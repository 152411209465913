

export const actionSetUser = (user) => ({
	type: 'SET_USER',
	user: user,
});

export const actionSetUsers = (users) => ({
	type: 'SET_USERS',
	users: users
});

export const actionSetMember = (member) => ({
  type: 'SET_MEMBER',
  member: member,
});

export const actionSetMembers = (members) => ({
  type: 'SET_MEMBERS',
  members: members
});


export const actionUsersRefresh = () => ({
	type: 'SET_USER_REFRESH'
});
