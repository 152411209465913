

export const actionSetPath = (activePath) => ({
	type: 'SET_PATH',
	activePath: activePath
});


export const actionSetVariables = (data) => ({
	type: 'SET_VARIABLES',
	variables: data
});

export const actionSetThemes = (data) => ({
	type: 'SET_THEMES',
	themes: data
});

export const actionSetScripts = (data) => ({
	type: 'SET_SCRIPTS',
	scripts: data
});

