import React, { Component } from 'react';

import styles from './LanguageButton.module.scss';

const codes2flag = {
  en: 'GB.png',
  de: 'DE.png',
  it: 'IT.png',
  fr: 'FR.png',
  es: 'ES.png',
  pt: 'PT.png',
  nl: 'NL.png',
  pl: 'PL.png',
  tr: 'TR.png',
  ru: 'RU.png',  
  ro: 'RO.png',
  jp: 'JP.png',
};



class LanguageButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { onClick, code, active } = this.props;

    return (
      <button
      className={active?styles.button_active:styles.button}
      onClick={() => {
          onClick(code);
        }}
      >
        <img
          className={styles.image}
          src={process.env.PUBLIC_URL + '/assets/flags/32/' + codes2flag[code]}
          alt="_image"
        ></img>
      </button>
    );
  }
}

export default LanguageButton;
