
export const updateModuleFromVM = (module, vmProjects) => {

  const project = vmProjects.find( project => project.key === module.storybuilder_key);
  if (!project) return;
  if (!project.data) return;

  module.storybuilder_name = project.name;

  if (project.data.variables) {
    // get label
    const nameVar = project.data.variables.find(
      (v) => v.name === 'moduleLabel'
    );
    if (nameVar) {
      const translation = project.data.translations.find(
        (t) => t.name === nameVar.value
      );
      if (translation) {
        delete translation._id;
        module.labels = translation;
      }
    }
  }

  let languages = module.languages || [];
  // add new
  if (project.data && project.data.languages) {
    project.data.languages.forEach((code) => {
      if (!languages.find((lang) => lang.code === code)) {
        languages.push({
          code,
          active: false,
        });
      }
    });

    // remove deleted
    languages = languages.filter((lang) => {
      return project.data.languages.indexOf(lang.code) !== -1;
    });
    module.languages = languages;
  }

  
  if (!module.languages.find((lang) => lang.code === 'de')) {
    module.languages.push({
      code: 'de',
      active: true,
    });
  }

};

