

const initialState = {
  members: null,
  member: null,
};


const reducerMember = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEMBERS':
      return {
        ...state,
        members: action.members
      };
    case 'SET_MEMBER':
      return {
        ...state,
        member: action.member,
      };
    default:
      return state;
  }
};

export default reducerMember;
