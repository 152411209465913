export const scopeIncludesOneOf = (scope, needs) => {
  if (scope && scope.length > 0) {
    for (let i = 0; i < needs.length; i++) {
      if (scope.indexOf(needs[i]) >= 0) {
        return true;
      }
    }
  }
  return false;
};

export const scopeRemove = (scope, toRemove) => {
  const index = scope.indexOf(toRemove);
  if (index > -1) scope.splice(index, 1);
};

export const scopeAdd = (scope, toAdd) => {
  const index = scope.indexOf(toAdd);
  if (index === -1) scope.push(toAdd);
};
