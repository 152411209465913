
//
// Redux init
// 
import { createStore, combineReducers } from 'redux'

import reducerMain from './reducer/reducerMain'
import reducerVuppetmaster from './reducer/reducerVuppetmaster'
import reducerUser from "./reducer/reducerUser";
import reducerMember from "./reducer/reducerMember";
import reducerFeedback from "./reducer/reducerFeedback";
import reducerModule from "./reducer/reducerModule";
import reducerResult from "./reducer/reducerResult";
import reducerCompany from "./reducer/reducerCompany";
import reducerSession from "./reducer/reducerSession";
import reducerViewControl from "./reducer/reducerViewControl";
import reducerLogin from "./reducer/reducerLogin";
import reducerSettings from './reducer/reducerSettings';

import reducerDrawContainer from './reducer/reducerDrawContainer';


const rootReducer = combineReducers({
	main: reducerMain,
	vuppetmaster: reducerVuppetmaster,
  login: reducerLogin,
  user: reducerUser,
	module: reducerModule,
	result: reducerResult,
	member: reducerMember,
	company: reducerCompany,
	feedback: reducerFeedback,
	drawContainer: reducerDrawContainer,
	session: reducerSession,
	viewControl: reducerViewControl,
	settings: reducerSettings
});

const store = createStore(
	rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;

