export const parseQueryString = query => {
  return (query || '').split('&').reduce((acc, pair) => {
    const match = pair.match(/^\??(.+?)(?:=(.*))?$/);
    if (match) {
      let value = match[2] ? decodeURIComponent(match[2]) : 'true';
      acc[decodeURIComponent(match[1])] = value;
    }
    return acc;
  }, {});
};
