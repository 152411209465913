import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Image,
  Dropdown,
  Menu,
  Segment,
  Visibility,
} from 'semantic-ui-react';

const SegmentStyled = styled(Segment)`
  minheight: 100px;
  padding: 1em 0em;
  text-align: center;
`;


const LoginInfo = (props) => {
  return (
    <Dropdown
      text={props.login && props.login.name}
      button
      className="icon"
      labeled
      icon="user"
    >
      <Dropdown.Menu>
        <Dropdown.Header
          content={'User: ' + (props.login && props.login.name)}
        />
        <Dropdown.Divider />
        <Dropdown.Item
          value={1}
          icon="log out"
          text="Logout"
          onClick={() => {
            props.onLogout();
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '0em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
};

const fixedMenuStyle = {
  border: '0px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
};

class DashboardFullScreen extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    //		<Responsive {...Responsive.onlyComputer}>
    return (
      <div style={{ height: '100%' }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <SegmentStyled vertical>
            <Menu
              style={fixed ? fixedMenuStyle : menuStyle}
              fixed={fixed ? 'top' : null}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Menu.Item header position="left">
                <Image
                  style={{ marginRight: '10px' }}
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/virtualtrainer_icon_flat_blue_32.png'
                  }
                />
                Virtual Trainer
              </Menu.Item>

              <Menu.Item header position="left">
              </Menu.Item>
              <Menu.Item position="right">
                <LoginInfo {...this.props} />
              </Menu.Item>
            </Menu>
          </SegmentStyled>
        </Visibility>

        {children}
      </div>
    );
  }
}

const DashboardFullScreenRouter = withRouter(DashboardFullScreen);

class Dashboard extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    //		<Responsive {...Responsive.onlyComputer}>
    return (
      <div style={{ height: '100%' }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <SegmentStyled vertical>
            <Menu
              style={fixed ? fixedMenuStyle : menuStyle}
              fixed={fixed ? 'top' : null}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Menu.Item header position="left">
                <Image
                  style={{ marginRight: '10px' }}
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/virtualtrainer_icon_flat_blue_32.png'
                  }
                />
                Virtual Trainer
              </Menu.Item>
              <Menu.Item position="right">
                <LoginInfo {...this.props} />
              </Menu.Item>
            </Menu>
          </SegmentStyled>
        </Visibility>
        {children}
      </div>
    );
  }
}

const DashboardRouter = withRouter(Dashboard);

//<MobileContainer {...props}>{props.children}</MobileContainer>
const DashboardContainer = (props) => (
  <div style={{ height: '100%' }}>
    {props.fullScreen ? (
      <div style={{ height: 'calc(100% - 140px)' }}>
        <DashboardFullScreenRouter {...props}>
          {props.children}
        </DashboardFullScreenRouter>
      </div>
    ) : (
      <div style={{ height: '100%' }}>
        <DashboardRouter {...props}>{props.children}</DashboardRouter>
      </div>
    )}
  </div>
);

export default withRouter(DashboardContainer);
