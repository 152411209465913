

export const actionSetResult = (result) => ({
	type: 'SET_RESULT',
	result: result,
});

export const actionSetResults = (results) => ({
	type: 'SET_RESULTS',
	results: results,
});
