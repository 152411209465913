export const cx = function cx(...args) {
  let classNames = [];

  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      switch (typeof args[i]) {
        case 'string':
          classNames.push(args[i]);
          break;
        case 'object':
          Object.keys(args[i]).forEach(c => {
            if (typeof args[i][c] === 'function' ? args[i][c]() : args[i][c]) {
              classNames.push(c);
            }
          });
          break;
        default:
          // DO NOTHING
          break;
      }
    }
  }

  return classNames.join(' ');
};
