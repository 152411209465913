import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Feedbacks.module.scss';

import { Dropdown, Flag, Message, Tab } from 'semantic-ui-react';

import { connect } from 'react-redux';
import store from '../../../redux/store';

import RestAPI, { modules, sessions } from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import { actionSetCompany } from '../../../redux/actions/actionCompany';
import { actionSetFeedbackSession } from '../../../redux/actions/actionFeedback';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import { actionSetModule } from '../../../redux/actions/actionModule';

class Feedbacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  onSelect(session_uuid) {
    const firstFeedback = this.props.feedbacks.find(
      (feedback) => feedback.session_uuid === session_uuid
    );

    const module =
      firstFeedback &&
      this.props.modules &&
      this.props.modules.find(
        (module) => module.uuid === firstFeedback.module_uuid
      );

    if (module) {
      RestAPI.vmProjectInfo(module.storybuilder_key).then((data) => {
        module.project = data.project;
        store.dispatch(actionSetModule(module));
      });
      store.dispatch(actionSetFeedbackSession(session_uuid));
      RestAPI.session(session_uuid);
      this.props.history.push('/admin/feedback/' + session_uuid);
    }
  }

  render() {
    const {
      companies,
      company,
      members,
      users,
      modules,
      feedbacks,
    } = this.props;

    const { tabIndex } = this.state;

    let reducedSessions =
      feedbacks && feedbacks.map((feedback) => feedback.session_uuid);

    reducedSessions = [...new Set(reducedSessions)];

    const rows =
      reducedSessions &&
      reducedSessions
        .map((session_uuid) => {
          const feedback = feedbacks.find(
            (feedback) => feedback.session_uuid === session_uuid
          );
          const module =
            modules &&
            modules.find((module) => module.uuid === feedback.module_uuid);

          let name = null;
          if (tabIndex === 0) {
            const member =
              members &&
              members.find((member) => member.uuid === feedback.member_uuid);
            if (member) name = member.name;
          }
          if (tabIndex === 1) {
            const user =
              users && users.find((user) => user.uuid === feedback.user_uuid);
            if (user) name = user.name;
          }
          if (!name) return null;

          const feedbackCompany =
            module &&
            companies &&
            companies.find((company) => company.uuid === module.company_uuid);

          if (
            feedbackCompany &&
            company &&
            company.uuid !== feedbackCompany.uuid
          ) {
            return null;
          }

          const sessionFeedbbacks =
            feedbacks.filter(
              (feedback) => feedback.session_uuid === session_uuid
            ) || [];
          const sessionFinishedFeedbbacks =
            sessionFeedbbacks.filter(
              (feedback) =>
                feedback.status &&
                feedback.status.find((stat) => stat === 'Finished')
            ) || [];
          const status = `(${sessionFeedbbacks.length}/${
            sessionFinishedFeedbbacks.length
          }) ${
            sessionFeedbbacks.length === sessionFinishedFeedbbacks.length
              ? '✔️'
              : ''
          }`;

          return {
            name: name,
            session_uuid: session_uuid,
            created: feedback.created,
            company: feedbackCompany && feedbackCompany.name,
            module: module && module.name,
            status,
            language: feedback.language,
          };
        })
        .filter(Boolean);

    const language = (feedback) => {
      return (
        <Flag
          key={feedback.language}
          name={feedback.language === 'en' ? 'gb' : feedback.language}
        />
      );
    };

    const status = (feedback) => {
      return <div></div>;
    };

    const columns = [
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
      },
      {
        name: 'Module',
        key: 'module',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Language',
        key: 'language',
        render: (feedback, key) => {
          return language(feedback);
        },
        disabled: true,
        singleLine: true,
      },
      {
        name: 'All/Finished',
        key: 'status',
        disabled: true,
        singleLine: true,
      },
      !company && {
        name: 'Company',
        key: 'company',
        disabled: true,
        singleLine: true,
      },
    ];

    const optCompanies = arrayDropDown(companies, 'all');

    const Table = (props) => {
      return (
        <div>
          <DataTable
            name="feedbacks"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            onSelect={(row) => this.onSelect(row.session_uuid)}
            updateView={() => {}}
          />
        </div>
      );
    };

    const panes = [
      {
        menuItem: 'Members',
        render: () => (
          <Tab.Pane>
            <Table />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Users',
        render: () => (
          <Tab.Pane>
            <Table />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Feedbacks</h3>
        <Message>
          Company:
          {companies && companies.length > 1 && (
            <Dropdown
              placeholder="Filter Company"
              value={company ? company.uuid : 'undefined'}
              selection
              options={optCompanies}
              onChange={(e, d) => {
                const company = companies.find(
                  (company) => company.uuid === d.value
                );
                RestAPI.changeCompany(company && company.uuid).then(() => {
                  RestAPI.members();
                  RestAPI.modules();
                  RestAPI.feedbacks();
                });
                store.dispatch(actionSetCompany(company));
              }}
            />
          )}
        </Message>{' '}
        <Tab
          panes={panes}
          activeIndex={tabIndex}
          onTabChange={(event, data) => {
            this.setState({ tabIndex: data.activeIndex });
          }}
        ></Tab>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Feedbacks = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    modules: state.module.modules,
    feedbacks: state.feedback.feedbacks,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(Feedbacks);

export default withRouter(Feedbacks);
