const initialState = {
  modules: null,
  module: null,
  language: null,
  moduleAssignments: null,
};

const reducerModule = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODULES':
      return {
        ...state,
        modules: action.modules,
      };
    case 'SET_MODULE':
      return {
        ...state,
        module: action.module,
      };
    case 'SET_MODULE_ASSIGNMENTS':
      return {
        ...state,
        moduleAssignments: action.moduleAssignments,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};

export default reducerModule;
