import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Dropdown, Button, Segment, Flag, Message } from 'semantic-ui-react';

import { connect } from 'react-redux';
import {
  actionSetModule,
  actionSetModules,
} from '../../../redux/actions/actionModule';
import { actionSetCompany } from '../../../redux/actions/actionCompany';
import { actionSetVmProjects } from '../../../redux/actions/actionVuppetmaster';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { updateModuleFromVM } from '../../../helpers/module.helper';
import DataTable from '../../3_organisms/DataTable/DataTable';
import NewRowModal from '../../3_organisms/NewRowModal/NewRowModal';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import styles from './Modules.module.scss';
import ModalUpdate from '../../3_organisms/ModalUpdate';
import sleep from '../../../helpers/sleep';
const clone = require('clone');

class Modules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleToDelete: null,
      updateText: null,
    };
  }

  updateView() {
    if (this.props.login.ok) {
      store.dispatch(actionSetModules(null));
      RestAPI.modules();
      RestAPI.members();
      RestAPI.moduleAssignments();
    }
  }

  async updateModules() {
    // get vm project infos
    this.setState({ updateText: 'init' });
    const projectListResult = await RestAPI.vmProjectList();
    if (projectListResult.projects) {
      let vmProjects = [];
      let max = projectListResult.projects.length;
      let current = 0;
      for (let project of projectListResult.projects) {
        const result = await RestAPI.vmProjectInfo(project.key);
        current++;
        this.setState({ updateText: `loading (${current}/${max})` });

        if (result.project) {
          vmProjects.push({
            key: result.project.key,
            data: result.project,
            name: project && project.name,
            user: project && project.user,
            uuid: project && project.uuid,
          });
        }
      }
      store.dispatch(actionSetVmProjects(vmProjects));

      let modules = [];
      this.props.modules.forEach((module) => {
        let newModule = clone(module);
        updateModuleFromVM(newModule, vmProjects);
        modules.push(newModule);
      });
      store.dispatch(actionSetModules(modules));

      sleep(2000);

      current = 0;
      max = this.props.modules.length;
      for (let module of this.props.modules) {
        await RestAPI.updateModule(module);
        current++;
        this.setState({ updateText: `updating (${current}/${max})` });
      }
      this.setState({ updateText: null });
    }
  }

  onSelect(module) {
    store.dispatch(actionSetModule(module));
  }

  onAddNew(newModule) {
    const module = {
      name: newModule.name,
      labels: {},
      languages: [],
      company_uuid: this.props.company.uuid,
    };

    RestAPI.createModule(module).then((result) => {
      if (result) {
        module.uuid = result.uuid;
        module.confirmUserUD = true;
        module.showPause = true;
        store.dispatch(actionSetModule(module));
        this.updateView();
      }
    });
  }

  onDelete(module) {
    RestAPI.deleteModule(module).then(() => {
      this.updateView();
    });
  }

  onEdit(module) {
    if (module) {
      store.dispatch(actionSetModule(module));
      this.props.history.push('/admin/module/' + module.uuid);
    }
  }

  onResult(module) {
    if (module) {
      store.dispatch(actionSetModule(module));
      this.props.history.push('/admin/moduleresult/' + module.uuid);
    }
  }

  render() {
    const {
      members,
      sessions,
      modules,
      moduleAssignments,
      companies,
      company,
      user,
      login,
      vmprojects,
    } = this.props;

    const { moduleToDelete, showAddModal, updateText } = this.state;

    const isAdmin = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);
    const isUser = scopeIncludesOneOf(login && login.scope, ['user']);

    let validCompanies = [];
    if (isUser) {
      if (companies && user && user.companies_uuid)
        validCompanies = user.companies_uuid
          .map((uuid) => {
            return companies.find((company) => company.uuid === uuid);
          })
          .filter((company) => company);
    } else if (companies) validCompanies = companies;

    const optCompanies = arrayDropDown(validCompanies, 'all');

    let rows =
      (modules &&
        modules
          .filter((module) => {
            return validCompanies.find(
              (company) => module.company_uuid === company.uuid
            );
          })
          .filter((module) => {
            if (company) {
              return company.uuid === module.company_uuid;
            } else {
              return true;
            }
          })) ||
      [];

    rows = rows.map((module) => {
      let projectName = null;
      if (module.storybuilder_key) {
        // sb projects
        const vmProject = vmprojects.find(
          (entry) => entry.key === module.storybuilder_key
        );
        if (vmProject) projectName = vmProject.name;
        else {
          projectName = module.storybuilder_name;
        }
      }

      // Company
      let company =
        validCompanies &&
        validCompanies.find((c) => c.uuid === module.company_uuid);

      let ratingSat = null;
      let ratingContent = null;

      let totalMembers = null;
      let totalParticipated = null;
      let totalParticipatedFinished = null;
      if (company) {
        let allModuleSessions =
          (sessions && sessions.filter((s) => s.module_uuid === module.uuid)) ||
          [];

        const isMemberOfModule = (member, module) => {
          return moduleAssignments.find((ma) => {
            return (
              (ma.member_uuid === member.uuid &&
                ma.module_uuid === module.uuid) ||
              (ma.member_tag &&
                ((member.tag && ma.member_tag === member.tag) ||
                  ma.member_tag === 'all'))
            );
          })
            ? true
            : false;
        };

        // all members of modul
        totalMembers =
          moduleAssignments &&
          members &&
          members.filter((member) => {
            if (member.name.startsWith('demo')) {
              return false;
            } else {
              return isMemberOfModule(member, module);
            }
          });

        // all members participated
        totalParticipated =
          totalMembers &&
          totalMembers.filter((member) => {
            return allModuleSessions.find((s) => s.member_uuid === member.uuid);
          });
        totalParticipated = Array.from(new Set(totalParticipated));

        // all members finished
        totalParticipatedFinished =
          totalMembers &&
          totalMembers.filter((member) => {
            return allModuleSessions.find(
              (s) => s.member_uuid === member.uuid && s.status.finished
            );
          });
        totalParticipatedFinished = Array.from(
          new Set(totalParticipatedFinished)
        );

        let rate = {
          surveySatisfactionRating: {
            absolute: [0, 0, 0, 0, 0, 0],
            count: 0,
          },
          surveyContentRating: {
            absolute: [0, 0, 0, 0, 0, 0],
            count: 0,
          },
        };
        allModuleSessions.forEach((element) => {
          if (element.rating) {
            rate = element.rating.reduce((acc, current) => {
              acc[current.name].absolute[current.result] += 1;
              if (current.result) acc[current.name].count += 1;
              return acc;
            }, rate);
          }
        });

        rate.surveyContentRating.data = rate.surveyContentRating.absolute.map(
          (entry) => {
            if (rate.surveyContentRating.count)
              return Math.floor((100 * entry) / rate.surveyContentRating.count);
            else return 0;
          }
        );

        rate.surveySatisfactionRating.data =
          rate.surveySatisfactionRating.absolute.map((entry) => {
            if (rate.surveySatisfactionRating.count)
              return Math.floor(
                (100 * entry) / rate.surveySatisfactionRating.count
              );
            else return 0;
          });

        ratingSat = rate.surveySatisfactionRating.data
          .map((e) => e + '%')
          .join(' ');
        ratingContent = rate.surveyContentRating.data
          .map((e) => e + '%')
          .join(' ');
      }

      return {
        name: module.name,
        uuid: module.uuid,
        module: module,
        year: module.year ? module.year : '-',
        status: module.status ? module.status : '-',
        tag: module.tag ? module.tag : '',

        total: totalMembers ? totalMembers.length : '',
        participated: totalParticipated ? totalParticipated.length : '',
        finished: totalParticipatedFinished
          ? totalParticipatedFinished.length
          : '',
        projectName: projectName,
        company: company ? company.name : 'not set',
        ratingSatisfaction: ratingSat ? ratingSat : '',
        ratingContent: ratingContent ? ratingContent : '',
      };
    });

    const languages = (row) => {
      const flags = row.module.languages ? (
        row.module.languages.map((lang) => {
          if (lang.active) {
            return (
              <Flag
                key={lang.code}
                name={lang.code === 'en' ? 'gb' : lang.code}
              />
            );
          } else {
            return null;
          }
        })
      ) : (
        <div></div>
      );
      return flags;
    };

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Tag',
        key: 'tag',
        required: false,
        singleLine: true,
        header: false,
      },
      !company && {
        name: 'Company',
        key: 'company',
        required: false,
        singleLine: true,
        header: false,
      },
      {
        name: 'Year',
        key: 'year',
        required: false,
        singleLine: true,
        header: false,
      },
      {
        name: 'Status',
        key: 'status',
        required: false,
        singleLine: true,
        header: false,
      },
      {
        name: 'Year',
        key: 'year',
        required: false,
        singleLine: true,
        header: false,
      },
      !isUser && {
        name: 'Project',
        key: 'projectName',
        singleLine: true,
        disabled: true,
        header: false,
      },
      {
        name: 'Languages',
        key: 'languages',
        singleLine: true,
        render: (module, key) => {
          return languages(module);
        },
        disabled: true,
        header: false,
      },
      {
        group: 'Member',
        name: 'Members total',
        key: 'total',
        singleLine: true,
        disabled: true,
        header: false,
      },
    ];

    const actions = [
      isAdmin && {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit module',
        func: (row) => this.onEdit(row.module),
      },
    ];

    const actionsMenu = [
      isAdmin && {
        text: 'Result',
        icon: 'file outline',
        color: 'blue',
        tooltip: 'show result',
        func: (row) => this.onResult(row.module),
      },
      isAdmin && {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit module',
        func: (row) => this.onEdit(row.module),
      },
      isAdmin && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete module',
        func: (row) => this.setState({ moduleToDelete: row.module }),
      },
    ];

    return (
      <div className="users">
        <h3>Modules:</h3>
        <Message>
          Company:
          {validCompanies && validCompanies.length > 0 && (
            <Dropdown
              placeholder="Filter Company"
              search
              value={company ? company.uuid : 'undefined'}
              selection
              options={optCompanies}
              onChange={(e, d) => {
                const company = companies.find(
                  (company) => company.uuid === d.value
                );
                store.dispatch(actionSetCompany(company));
              }}
            />
          )}
        </Message>
        <DataTable
          tableProps={{ compact: true, celled: true }}
          name="modules"
          sortable={true}
          columns={columns}
          actions={actions}
          actionsMenu={actionsMenu}
          rows={rows}
          onSelect={(row) => this.onSelect(row.module)}
          generalActions={[
            isAdmin &&
              vmprojects &&
              company && {
                icon: 'add user',
                text: 'new module',
                color: 'blue',
                float: 'right',
                func: (event) => this.setState({ showAddModal: true }),
              },
          ]}
          updateView={() => this.updateView()}
        />
        {isAdmin && (
          <Message>
            <h4>Admin Area</h4>
            <div className={styles.update}>
              <Button
                onClick={() => {
                  this.updateModules();
                }}
              >
                Update
              </Button>
            </div>
          </Message>
        )}
        <ModalUpdate text={updateText} open={updateText ? true : false} />
        <ModalOkCancel
          text="Delete Module?"
          color="red"
          open={moduleToDelete != null}
          onOK={() => {
            this.onDelete(moduleToDelete);
            this.setState({ moduleToDelete: null });
          }}
          onCancel={() => this.setState({ moduleToDelete: null })}
        />
        {showAddModal && (
          <NewRowModal
            icon={'add user'}
            title={'new module'}
            fields={columns}
            onApply={(result) => {
              this.onAddNew(result);
              this.setState({ showAddModal: false });
            }}
            onCancel={() => this.setState({ showAddModal: false })}
          />
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Modules = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    members: state.member.members,
    module: state.module.module,
    modules: state.module.modules,
    sessions: state.session.sessions,
    companies: state.company.companies,
    company: state.company.company,
    moduleAssignments: state.module.moduleAssignments,
    vmprojects: state.vuppetmaster.vmprojects,
  }),
  {} // bind account loading action creator
)(Modules);

export default withRouter(Modules);
