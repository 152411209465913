import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';

import './Nothingtodo.css';

import { withRouter } from 'react-router-dom';
import Copyright from './../../1_atom/Copyright/Copyright';


const ViewWelcome = (props) => (
  <div>
    <h1>Gratulation. Sie habe die Unterweisung(en) schon absolviert</h1>
    <br />
    <Button
      style={{ width: '300px' }}
      color={'blue'}
      size="large"
      onClick={() => {
        props.history.push(`/`);
      }}
    >
      Weiter
    </Button>
  </div>
);

class Nothingtodo extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column>
            <ViewWelcome {...this.props} />
          </Grid.Column>
        </Grid>
        <div id="footer">
          <Copyright />
        </div>
      </div>
    );
  }
}

export default withRouter(Nothingtodo);
