import React, { Component } from 'react';

import { Segment, Header, Grid, List } from 'semantic-ui-react';

import { connect } from 'react-redux';

/**** MainFooter ***************************************************************/

/*!
 **
 ******************************************************************************/
class MainFooter extends Component {
  render() {
    return (
      <div>
        <Segment
          vertical
          style={{
            height: '180px',
            backgroundColor: '#3d3d3d',
            padding: '5em 0em',
          }}
        >
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="About" />
                <List link inverted>
                  <List.Item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.vuppetmaster.de"
                    >
                      Vuppetmaster
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.charamel.com/impressum"
                    >
                      Impressum
                    </a>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Services" />
                <List link inverted>
                  <List.Item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.charamel.com"
                    >
                      Charamel GmbH
                    </a>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={7}></Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <div
          style={{
            color: '#4d4d4d',
            textAlign: 'right',
            height: '33px',
            paddingRight: '22px',
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: '#2a2a2a',
          }}
        >
          © Charamel GmbH 2022
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
MainFooter = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
  }),
  {} // bind account loading action creator
)(MainFooter);

export default MainFooter;
