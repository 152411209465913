import React, { Component } from 'react';
import {
  Progress,
  Button,
  Grid,
  Icon,
  Checkbox,
  Message,
} from 'semantic-ui-react';

import styles from './TrainerStart.module.scss';

import { withRouter } from 'react-router-dom';
import { translate } from '../../../helpers/translate';
import { connect } from 'react-redux';
import Copyright from './../../1_atom/Copyright/Copyright';


const LoadingBar = (props) => (
  <div className={styles.loadingbar}>
    <h1>{translate('loading', props.language)}</h1>
    <br />
    <Progress color="grey" percent={props.progress} size="tiny"></Progress>
    <br />
  </div>
);

const Confirm = (props) => {
  return (
    <div className={styles.confirm_main}>
      <div className={styles.confirm_header}>
        <h2>{translate('confirm0', props.language)}</h2>
      </div>
      <div className={styles.confirm_1}>
        {translate('confirm1', props.language)}
      </div>
      <div className={styles.confirm_check}>
        <Checkbox
          checked={props.confirmed}
          style={{ textAlign: 'left', padding: '20px 0px 20px 0px' }}
          label={translate('confirm2', props.language)}
          onChange={(event, data) => {
            props.onCheck(data.checked);
          }}
        />
      </div>
    </div>
  );
};

class TrainerStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: props.confirmUserID ? false : true,
    };
  }

  render() {
    const {
      showProgress,
      onStart,
      language,
      confirmUserID,
      showWait,
    } = this.props;

    const { confirmed } = this.state;

    const MessageMain = () => {
      return (
        <div>
          {confirmUserID && (
            <Confirm
              language={language}
              confirmed={confirmed}
              onCheck={(flag) => {
                this.setState({ confirmed: flag });
              }}
            />
          )}
          <div className={styles.startbutton}>
            <Button
              disabled={!confirmed || showWait}
              color={'blue'}
              onClick={() => {
                onStart();
              }}
            >
              {translate('start', language)}
            </Button>
            {showWait && (
              <Icon size={'large'} name="circle notch" loading></Icon>
            )}
          </div>
        </div>
      );
    };

    const MessageDesktop = () => {
      if (confirmUserID) {
        return (
          <Message>
            <MessageMain />
          </Message>
        );
      } else
        return (
          <div>
            <MessageMain />
          </div>
        );
    };

    const MessageMobile = () => {
      return (
        <div>
          <MessageMain />
        </div>
      );
    };

    return (
      <div className={styles.main}>
        <Grid
          textAlign="center"
          style={{ height: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column>
            {showProgress ? (
              <LoadingBar {...this.props} />
            ) : (
              <div className={styles.message}>
                <div className={styles.messageDesktop}>
                  <MessageDesktop />
                </div>
                <div className={styles.messageMobile}>
                  <MessageMobile />
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid>

        <div className={styles.loadingfooter}>
          <Copyright showLink={false} language={language}/>
        </div>
      </div>
    );
  }
}

TrainerStart = connect(
  (state) => ({
    language: state.module.language,
  }),
  {}
)(TrainerStart);

export default withRouter(TrainerStart);
