import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Segment } from 'semantic-ui-react';

import Script from 'react-load-script';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import TrainerView2 from './../../4_views/TrainerView/TrainerView2.js';
import Members from './../../4_views/Members/Members.js';
import Results from './../../4_views/Results/Results.js';
import Modules from './../../4_views/Modules/Modules.js';
import Module from './../../4_views/Modules/Module.js';
import ModuleResult from './../../4_views/Modules/ModuleResult.js';
import Session from './../../4_views/Sessions/Session.js';
import Sessions from './../../4_views/Sessions/Sessions.js';
import Company from './../../4_views/Company/Company.js';
import Companies from './../../4_views/Company/Companies.js';
import Users from './../Users/Users.js';
import User from './../Users/User.js';
import Assignments from './../Assignments/Assignments.js';
import Feedbacks from './../Feedback/Feedbacks.js';
import Feedback from './../Feedback/Feedback.js';
import Select from './../../4_views/Select/Select';
import Login from './../../4_views/Landing/Login/Login';
import Nothingtodo from './../../4_views/Nothingtodo/Nothingtodo';
import KeyHandler from './../../3_organisms/KeyHandler/KeyHandler';
import MainFooter from './MainFooter';
import DebugView from './../../3_organisms/DebugView/DebugView';
import { API_ROOT_VM4, API_ROOT_DASHBOARD } from '../../../api-config';

import styles from './MainPage.module.scss';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { actionAddMessage } from '../../../redux/actions/actionVuppetmaster';
import { actionSetPath } from '../../../redux/actions/actionMain';
import { actionSetUser } from '../../../redux/actions/actionUser';
import { actionSetIpData } from '../../../redux/actions/actionLogin';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI.js';
import { actionSetLogin } from '../../../redux/actions/actionLogin';
import DashboardContainer from './DashboardContainer';
import AdminMenu from './../../3_organisms/AdminMenu/AdminMenu';
import { parseQueryString } from './../../../helpers/URL.helper';
import Ticket from '../Ticket/Ticket.js';
import InvalidLink from '../InvalidLink/InvalidLink.js';

import Vuppetmaster from '../../../helpers/VuppetMaster';
import Confirm from '../Landing/Confirm/Confirm.js';

const publicIp = require('public-ip');

/**** MainPage ***************************************************************/
/*!
 **
 ******************************************************************************/
class MainPage extends Component {
  constructor(props) {
    super(props);

    Vuppetmaster.create();

    let title = 'Virtual Trainer';

    if (process.env.NODE_ENV !== 'production') {
      title += ' ' + process.env.NODE_ENV;
    }

    if (process.env.MODE === 'offline') {
      console.log('### mode offline ####');
      title += 'Virtual Trainer';
    }

    this.state = {
      initialized: false,
      loggingMessage: '',
      loggedIn: false,
      mode: 'login', // login, admin, trainer
      title: title,
      vmurl: null,
    };
  }

  async componentDidMount() {
    const vmurl = API_ROOT_VM4 + '/api?key=trainer';
    this.setState({ vmurl });

    const path = this.props.location.pathname;

    if (path.startsWith('/confirm')) {
    } else if (path.startsWith('/code')) {
      // login with passcode! -> check it
      const query = parseQueryString(window.location.search);
      const code = query.c;
      const user_uuid = query.u;
      if (code && user_uuid) {
        await RestAPI.logout();
        RestAPI.code(user_uuid, code).then((result) => {
          this.onCode(result);
        });
      } else {
        // check cookie
        RestAPI.authStatus().then((result) => {
          if (result.ok && result.passcode) {
            this.onCode(result);
          } else {
          }
        });
      }
    } else {
      // check if token is still valid
      RestAPI.authStatus().then((result) => {
        if (!result.ok) {
          console.log('token invalid -> login ', result.error);
          this.props.history.push('/');
        } else {
          this.setState({
            loggedIn: true,
          });

          if (
            path.startsWith('/admin') ||
            path.startsWith('/trainer') ||
            path.startsWith('/user') ||
            path.startsWith('/select')
          ) {
            this.onLogin(result);
          }
        }
      });
    }
  }

  handleScriptLoad() {
    Vuppetmaster.instance.init();
  }

  setIpData() {
    if (process.env.MODE !== 'offline') {
      publicIp.v4().then((ip) => {
        RestAPI.getipdata(ip).then((result) => {
          store.dispatch(actionSetIpData(result.data));
        });
      });
    } else {
      store.dispatch(actionSetIpData({ ip: 'offline' }));
    }
  }

  onCode() {
    this.setIpData();
    window.location.href = API_ROOT_DASHBOARD;
    /*
    RestAPI.member(loginData.uuid).then((member) => {
      if (member && member.code) {
        this.setState({
          loggedIn: true,
          root: false,
          mode: 'ticket',
          initialized: true,
        });
        this.props.history.push('/ticket');
      } else {
        this.props.history.push('/invalidlink');
      }
    });*/
  }

  async onLogin(loginData) {
    this.setIpData();

    const scope =
      loginData.scope && loginData.scope.length && loginData.scope[0];
    const role = loginData.role && loginData.role.permission;
    if (scopeIncludesOneOf(scope, ['member'])) {
      //
      // Member
      //
      if (
        loginData.company_features &&
        loginData.company_features.enable_memberDashboard
      ) {
        window.location.href = API_ROOT_DASHBOARD;
      } else {
        RestAPI.member(loginData.uuid).then((member) => {
          if (member) {
            if (member.passcode) {
              this.setState({
                loggedIn: true,
                root: false,
                mode: 'ticket',
                initialized: true,
              });
              this.props.history.push('/ticket');
            } else {
              this.setState({
                loggedIn: true,
                root: false,
                mode: 'select',
                initialized: true,
              });
              this.props.history.push('/select');
            }
          }
        });
      }
      //  this.setState({ initialized: true });
    } else if (scopeIncludesOneOf(scope, ['root'])) {
      //
      // Root
      //
      this.setState({ loggedIn: true, mode: 'admin' });
      store.dispatch(actionSetUser(null));
      RestAPI.members();
      RestAPI.companies();
      RestAPI.changeCompany();
      this.props.history.push('/admin/modules');
      store.dispatch(actionSetPath('modules'));
      RestAPI.modules().then(async () => {
        this.setState({ initialized: true });
      });
    } else {
      //
      // User
      //
      const query = parseQueryString(window.location.search);
      const module_uuid = query.module;
      const language = query.lang;
      if (module_uuid) {
        if (role.isAdmin) {
          // preview module
          store.dispatch(actionSetUser({ name: 'admin' }));
          this.props.history.push(
            `/select?module=${module_uuid}&lang=${language}`
          );
        } else {
          // preview modules
          store.dispatch(
            actionSetUser({ name: loginData, uuid: loginData.uuid })
          );
          this.props.history.push(
            `/select?module=${module_uuid}&lang=${language}`
          );
        }
      } else {
        // goto dashboard
        window.location.href = API_ROOT_DASHBOARD;
      }
    }
  }

  onLogout() {
    RestAPI.logout();
    store.dispatch(actionSetUser(null));
    store.dispatch(actionSetLogin(null));
    this.setState({ loggedIn: false, user: '-', mode: 'login' });
    this.props.history.push('/');
  }

  render() {
    const { vmurl, initialized } = this.state;
    const { module, member, user } = this.props;

    let fullScreen = this.props.location.pathname.startsWith('/admin/pages');
    fullScreen |= this.props.location.pathname.startsWith('/testmode');
    fullScreen |= this.props.location.pathname.startsWith('/demo');
    fullScreen |= this.props.location.pathname.startsWith('/trainer');

    return (
      <KeyHandler>
        <div className={fullScreen ? styles.mainFullscreen : styles.main}>
          {vmurl && (
            <Script
              url={vmurl}
              onCreate={() => {}}
              onError={() => {
                store.dispatch(actionAddMessage('vm loading error'));
                console.log('script load error');
              }}
              onLoad={this.handleScriptLoad.bind(this)}
            />
          )}

          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => {
                return (
                  <Login
                    onCode={(data) => this.onCode(data)}
                    onLogin={(data) => this.onLogin(data)}
                  />
                );
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return <Login onLogin={(data) => this.onLogin(data)} />;
              }}
            />
            <Route
              exact
              path="/code"
              render={(props) => {
                return (
                  <Login mode={'code'} onLogin={(data) => this.onLogin(data)} />
                );
              }}
            />
            <Route
              exact
              path="/confirm/:key"
              render={(props) => {
                return <Confirm />;
              }}
            />
            <Route
              exact
              path="/ticket"
              render={(props) => {
                if (member) return <Ticket />;
                else return <div>Loading ...</div>;
              }}
            />
            <Route
              exact
              path="/nothingtodo"
              render={(props) => {
                return <Nothingtodo />;
              }}
            />
            <Route
              exact
              path="/invalidlink"
              render={(props) => {
                return <InvalidLink />;
              }}
            />
            <Route
              exact
              path="/select"
              render={(props) => {
                if (member || user) return <Select />;
                else return <div>Loading select...</div>;
              }}
            />
            <Route
              path="/trainer2"
              render={(props) => {
                if (module) return <TrainerView2 />;
                else return <div>Loading...</div>;
              }}
            />
            <Route
              path="/admin"
              render={(props) => {
                return (
                  <div style={{ height: '100%' }}>
                    <div
                      className={
                        fullScreen ? styles.mainFullscreen : styles.pagemain
                      }
                    >
                      <div
                        className={
                          fullScreen ? styles.mainFullscreen : styles.pagemain
                        }
                      >
                        <DashboardContainer
                          initialized={initialized}
                          fullScreen={fullScreen}
                          {...this.props}
                          page={this.props.page}
                          mode={this.state.mode}
                          onLogout={() => this.onLogout()}
                        >
                          <div className={styles.flexcontainer}>
                            <div style={{ flex: '0 0 150px' }}>
                              <AdminMenu
                                scope={
                                  this.props.login && this.props.login.scope
                                }
                                activePath={this.props.activePath}
                                history={this.props.history}
                              />
                            </div>
                            <div>
                              <Segment
                                style={{
                                  height: '100%',
                                  overflow: 'auto',
                                  padding: '0,0,0,0',
                                }}
                              >
                                {initialized ? (
                                  <div style={{ height: '100%' }}>
                                    <Route
                                      exact
                                      path="/admin/user/:uuid"
                                      render={(props) => {
                                        return <User />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/users"
                                      render={(props) => {
                                        return <Users />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/assignments"
                                      render={(props) => {
                                        return <Assignments />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/feedbacks"
                                      render={(props) => {
                                        return <Feedbacks />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/feedback/:uuid"
                                      render={(props) => {
                                        return <Feedback />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/module/:uuid"
                                      render={(props) => {
                                        return <Module />;
                                      }}
                                    />
                                    {module && (
                                      <Route
                                        exact
                                        path="/admin/moduleresult/:uuid"
                                        render={(props) => {
                                          return <ModuleResult />;
                                        }}
                                      />
                                    )}
                                    <Route
                                      exact
                                      path="/admin/results"
                                      render={(props) => {
                                        return <Results />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/modules"
                                      render={(props) => {
                                        return <Modules />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/members"
                                      render={(props) => {
                                        return <Members />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/session/:uuid"
                                      render={(props) => {
                                        return <Session />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/sessions"
                                      render={(props) => {
                                        return <Sessions />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/company/:uuid"
                                      render={(props) => {
                                        return <Company />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/companies"
                                      render={(props) => {
                                        return <Companies />;
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>loading</div>
                                )}
                              </Segment>
                            </div>
                          </div>
                        </DashboardContainer>
                        {!fullScreen && <div className={styles.push} />}
                      </div>
                    </div>
                    {!fullScreen && (
                      <div className={styles.footer}>
                        <MainFooter />
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </Switch>
        </div>
        <DebugView />
      </KeyHandler>
    );
  }
}

MainPage = connect(
  (state) => ({
    login: state.login.login,
    module: state.module.module,
    modules: state.module.modules,
    member: state.member.member,
    user: state.user.user,
    activePath: state.main.activePath,
  }),
  {}
)(MainPage);

export default withRouter(MainPage);
