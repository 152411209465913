import React, { useState, useEffect } from 'react';
import styles from './DrawContainer.module.scss';
import { getStroke } from 'perfect-freehand';
import { getSvgPathFromStroke } from './utils';
import useDimensions from 'react-cool-dimensions';
import { useSelector } from 'react-redux';
import store from '../../../redux/store';
import UUID from 'uuid/v4';
import { actionDrawContainerSetPoints } from '../../../redux/actions/actionDrawContainer';

var _rect = null;

const DrawContainer = () => {
  const [points, setPoints] = useState([]);
  const { observe, width, height } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });

  useEffect(() => {
    setPoints([]);
  }, []);

  const color = useSelector((state) => state.drawContainer.color);
  const feedbackDrawPoints = useSelector((state) => state.drawContainer.points);

  function handlePointerUp(e) {
    const newPoints = [
      ...feedbackDrawPoints,
      {
        color: color,
        points,
        uuid: UUID(),
      },
    ];

    store.dispatch(actionDrawContainerSetPoints(newPoints));

    setPoints([]);
  }

  function handlePointerDown(e) {
    _rect = e.target.getBoundingClientRect();

    e.target.setPointerCapture(e.pointerId);
    setPoints([
      [
        (e.pageX - _rect.left) / _rect.width,
        (e.pageY - _rect.top) / _rect.height,
        e.pressure,
      ],
    ]);
  }

  function handlePointerMove(e) {
    if (e.buttons !== 1) return;
    _rect = e.target.getBoundingClientRect();
    setPoints([
      ...points,
      [
        (e.pageX - _rect.left) / _rect.width,
        (e.pageY - _rect.top) / _rect.height,
        e.pressure,
      ],
    ]);
  }

  const getPathData = (points) => {
    const pp = points.map((entry) => {
      return [entry[0] * width, entry[1] * height, entry[2]];
    });
    const stroke = getStroke(pp, {
      size: 6,
      thinning: 0.5,
      smoothing: 0.5,
      streamline: 0.5,
    });

    const pathData = getSvgPathFromStroke(stroke);
    return pathData;
  };

  return (
    <div className={styles.main} ref={observe}>
      {feedbackDrawPoints.map((entry) => {
        return (
          <svg
            key={entry.uuid}
            className={styles.main}
            style={{ touchAction: 'none', fill: entry.color }}
          >
            {entry.points && <path d={getPathData(entry.points)} />}
          </svg>
        );
      })}
      <svg
        className={styles.main}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        style={{ touchAction: 'none', fill: color }}
      >
        {points && <path d={getPathData(points)} />}
      </svg>
    </div>
  );
};

export default DrawContainer;
