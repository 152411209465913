import React from 'react';
import { Modal, Segment, Header, Icon } from 'semantic-ui-react';

const ModalUpdate = ({ open, text }) => (
  <div style={{ width: '400px' }}>
    <Modal open={open} size={'mini'}>
      <Header content="Updating" />
      <Modal.Content>
        <Segment><Icon loading name={'circle notched'}></Icon>{text}</Segment>
      </Modal.Content>
    </Modal>
  </div>
);

export default ModalUpdate;
