import React, { Component } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../../redux/store';

import styles from './Select.module.scss';
import { memoize, isEqual } from 'lodash-es';

import RestAPI from '../../../RestAPI';
import { actionSetSession } from '../../../redux/actions/actionSession';
import {
  actionSetModule,
  actionSetLanguage,
} from '../../../redux/actions/actionModule';
import { actionSetStartSequenceUUID } from '../../../redux/actions/actionVuppetmaster';

import { translate } from '../../../helpers/translate';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { allLanguages } from '../../../helpers/alllanguages';
import Copyright from './../../1_atom/Copyright/Copyright';
import HelpModal from './../../1_atom/HelpModal/HelpModal';
import LanguageButton from '../../1_atom/LanguageButton/LanguageButton';
import MediaQuery from 'react-responsive';
import { parseQueryString } from '../../../helpers/URL.helper';
import Vuppetmaster from '../../../helpers/VuppetMaster';

const { detect } = require('detect-browser');
const browser = detect();

const clone = require('clone');
const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

const minWidth = 650;

const LoadingModules = () => (
  <div>
    <Icon size={'large'} name="circle notch" loading></Icon>
  </div>
);

const SelectLanguage = (props) => {
  return (
    <div className="selectlanguage">
      {props.languages.map((lang) => {
        const entry = allLanguages.find((entry) => entry.code === lang.code);
        if (entry)
          return (
            <LanguageButton
              key={lang.code}
              active={lang.code === props.active}
              code={lang.code}
              label={entry && entry.original ? entry.original : entry.text}
              onClick={() => {
                props.onLanguage(lang.code);
              }}
            />
          );
        else return <div />;
      })}
    </div>
  );
};

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_modules: null,
      selected: false,
      languages: [],
      language: 'de',
      member_uuid: null,
      isDemoMember: false,
      error: null,
    };
  }

  async componentDidMount() {
    this.setState({ selected: false });

    if (!(this.props.member || this.props.user)) {
      return;
    }

    let clientdata = {};
    if (md.mobile()) {
      clientdata.mobile = {
        phone: md.phone(),
        tablet: md.tablet(),
        userAgent: md.userAgent(),
        os: md.os(),
        isiphone: md.is('iPhone'),
      };
    }

    clientdata.browser = {
      name: browser.name,
      version: browser.version,
      os: browser.os,
      ip: null,
    };

    // create a new session
    let newSession = {
      version: '2.0.0',
      module_uuid: null,
      sequences: null,
      member_uuid: this.props.member ? this.props.member.uuid : null,
      user_uuid: this.props.user ? this.props.user.uuid : null,
      finished: false, // obsolete
      status: {
        finished: false,
        end: false,
      },
      scoreMin: 0,
      scoreMax: 0,
      scoreCurrent: 0,
      variables: null,
      clientdata: clientdata,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    const result = await RestAPI.createSession(newSession);
    if (result) {
      newSession.uuid = result.uuid;
      store.dispatch(actionSetSession(newSession));
    }

    let languages = [];
    const setModuleLanguages = (module) => {
      if (module.languages) {
        // set languages
        module.languages
          .filter((lang) => lang.active)
          .forEach((lang) => {
            if (!languages.find((entry) => entry.code === lang.code)) {
              languages.push(lang);
            }
          });
      }
      this.setState({ languages });
    };

    const query = parseQueryString(window.location.search);
    const module_uuid = query.module;
    const language = query.lang;
    if (module_uuid && this.props.user) {
      //
      // Get user modules
      //
      const module = await RestAPI.moduleSingle(module_uuid);
      setModuleLanguages(module);
      this.setState({ language, member_modules: [module] });
    } else {
      //
      // Get member modules
      //

      //
      let member_uuid = this.props.member.uuid;
      let isDemoMember =
        scopeIncludesOneOf(this.props.member.scope, ['demo']) ||
        this.props.member.name.startsWith('demo');

      this.setState({ isDemoMember, member_uuid });

      let resultModules = await RestAPI.modulesByMember(this.props.member.uuid);
      if (resultModules && resultModules.ok && resultModules.data) {
        let member_modules = [];

        resultModules.data.forEach(async (module) => {
          module.finished = false;

          if (module.sessions && !isDemoMember) {
            // check if a finished session exists
            let finishedSession = module.sessions.find(
              (s) => s.finished || (s.status && s.status.finished)
            );
            if (finishedSession) {
              module.finished = true;
            }
          }

          // check db collection results
          if (module.result) {
            if (module.result.finished) {
              module.finished = true;
            }
          }

          setModuleLanguages(module);
          member_modules.push(module);
        });
        this.setState({ member_modules: member_modules });
      }

      // check languages
      if (languages.length > 0) {
        // check for german
        if (languages.find((entry) => entry.code === 'de' && entry.active)) {
          this.setState({ language: 'de' });
        } else if (this.state.language !== languages[0].code)
          this.setState({ language: languages[0].code });
      }
    }

    let id = setInterval(() => {
      if (this.props.ipdata && this.props.session && Vuppetmaster.instance.vm) {
        clearInterval(id);
        let newSession = clone(this.props.session);
        newSession.clientdata.ip = this.props.ipdata;
        if (!newSession.info) newSession.info = [];
        if (typeof Vuppetmaster.instance.vm === 'string') {
          this.setState({
            error: `Error Code 34 (${Vuppetmaster.instance.vm})`,
          });
          newSession.info.push({ error: Vuppetmaster.instance.vm });
        }
        RestAPI.updateSession(newSession);
      }
    }, 300);
  }

  onLogout() {
    RestAPI.logout();
    this.props.history.push('/login');
  }

  async onStart(module) {
    this.setState({ selected: true });

    // check if vuppetmaster is loaded
    if (!Vuppetmaster.instance.vm) {
      return;
    }

    let sequences = null;

    if (module.storybuilder_key) {
      sequences = [];
      store.dispatch(actionSetModule(module));
      store.dispatch(actionSetLanguage(this.state.language));
    }

    if (module.finished) {
      // no need to make a tutorial
      this.props.history.push('/nothingtodo');
      return;
    }

    let variables = [];
    let scoreCurrent = 0;

    let repeatModule = false;

    if (this.props.member && !this.state.isDemoMember) {
      // find last existen and NOT ended session
      if (module.sessions && module.sessions.length > 0) {
        let lastSession = module.sessions
          .reverse()
          .find((session) => session.sequences && session.sequences.length > 0);
        if (lastSession) {
          scoreCurrent = lastSession.scoreCurrent;

          repeatModule = lastSession.status && lastSession.status.end;
          if (repeatModule) {
            store.dispatch(actionSetStartSequenceUUID(null));
          } else {
            let lastSequence = lastSession.sequences.pop();
            store.dispatch(actionSetStartSequenceUUID(lastSequence.uuid));
          }
        }
      }
    }

    let newSession = clone(this.props.session);

    // fill new session
    newSession.module_uuid = module.uuid;
    newSession.sequences = sequences;
    newSession.member_uuid = this.props.member ? this.props.member.uuid : null;
    newSession.user_uuid = this.props.user ? this.props.user.uuid : null;
    newSession.status = {
      end: false,
      finished: false,
    };
    newSession.language = this.state.language;

    newSession.scoreCurrent = repeatModule ? 0 : scoreCurrent;

    newSession.created = new Date().toISOString();
    newSession.variables = variables;
    newSession.clientdata.ip = this.props.ipdata;
    store.dispatch(actionSetSession(newSession));

    RestAPI.updateSession(newSession).then((result) => {
      this.props.history.push('/trainer2');
    });
  }

  render() {
    const { selected, member_modules, language, languages, error } = this.state;
    const { member, user } = this.props;

    const modulesRowsAll =
      member_modules &&
      member_modules
        //
        // Sort
        //
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        //
        // Filter language
        //
        .filter((module) => {
          if (module.languages)
            return module.languages.find(
              (entry) => entry.active && entry.code === language
            );
          else return true;
        })
        //
        // Filter from until period
        //
        .filter((module) => {
          if (module.from && module.until) {
            const now = Date.now();
            const from = new Date(module.from);
            let until = new Date(module.until);
            until = new Date(
              until.getFullYear(),
              until.getMonth(),
              until.getDate()
            );
            until.setDate(until.getDate() + 1);
            if (now > from && now <= until) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        });

    // Filter duplicates
    const modulesClean =
      modulesRowsAll &&
      modulesRowsAll.filter(function (item, pos) {
        return (
          modulesRowsAll.findIndex((entry) => entry.uuid === item.uuid) === pos
        );
      });

    const modulesRows =
      modulesClean &&
      modulesClean.map((module, index) => {
        // Name of the module
        let label = module.name;
        if (module.labels) {
          // use sb label
          if (module.labels[language] !== undefined) {
            label = module.labels[language];
            label = label.replace(/&amp;/g, '&');
          }
        }
        if (label.length === 0) {
          return <div key={index}></div>;
        }

        return (
          <div className={styles.modulebutton} key={index}>
            <Button
              fluid
              color={'blue'}
              icon
              labelPosition="right"
              disabled={module.finished || selected}
              onClick={() => {
                if (!error) this.onStart(module);
              }}
            >
              {label}
              {module.finished && (
                <Icon size={'large'} color={'green'} name="check" />
              )}
            </Button>
          </div>
        );
      });

    let moduleState = modulesRows ? 'loading' : 'unknown';
    moduleState = modulesRows && modulesRows.length > 0 ? 'modules' : 'empty';

    return (
      <div className={styles.main}>
        <div className={styles.language}>
          {languages.length > 1 && (
            <SelectLanguage
              active={language}
              languages={languages}
              onLanguage={(language) => {
                this.setState({ language });
              }}
            />
          )}
        </div>
        <div className={styles.helpbutton}>
          <Button
            size={'mini'}
            icon
            onClick={() => {
              this.setState({ openHelp: true });
            }}
          >
            {translate('help', language)}
            <Icon name={'help'} />
          </Button>
        </div>

        <MediaQuery style={{ height: '100%' }} minWidth={minWidth}>
          <div className={styles.flex_container}>
            <div className={styles.flex_item1}>
              <div className={styles.image}>
                <Image
                  src={
                    process.env.PUBLIC_URL + '/assets/gloria_walkpresent.png'
                  }
                />
              </div>
            </div>
            <div className={styles.flex_item2}>
              <div className={styles.select}>
                <h1>
                  {translate('welcome1', language)}
                  <p />
                  {translate('welcome2', language)}
                </h1>
                <p />
                <h3>{translate('title', language)}</h3>
                <p />
                {moduleState === 'loading' && <LoadingModules />}
                {moduleState === 'empty' && (
                  <div> {translate('module_empty', language)}</div>
                )}
                {moduleState === 'modules' && modulesRows}
                <p />
                <p />
                {member && (
                  <div className={styles.logout}>
                    <Button onClick={() => this.onLogout()}>
                      {translate('logout', language)}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={minWidth - 1}>
          <div className={styles.select_mobile}>
            <h1>
              {translate('welcome1', language)}
              <p />
              {translate('welcome2', language)}
            </h1>
            <p />
            <h3>{translate('title', language)}</h3>
            <p />
            {moduleState === 'loading' && <LoadingModules />}
            {moduleState === 'empty' && (
              <div> {translate('module_empty', language)}</div>
            )}
            {moduleState === 'modules' && modulesRows}
            {member && (
              <div className={styles.logout}>
                <Button onClick={() => this.onLogout()}>
                  {translate('logout', language)}
                </Button>
              </div>
            )}
          </div>
        </MediaQuery>
        <div className={styles.footer}>
          <Copyright language={language} />
        </div>
        <HelpModal
          open={this.state.openHelp}
          onClose={() => {
            this.setState({ openHelp: false });
          }}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Select = connect(
  (state) => ({
    ipdata: state.login.ipdata,
    session: state.session.session,
    member: state.member.member,
    user: state.user.user,
    modules: state.module.modules,
    debug: state.settings.debug,
  }),
  {} // bind account loading action creator
)(Select);

export default withRouter(Select);
