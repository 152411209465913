declare global {
  interface Window {
    VM: any;
    TWEEN: any;
  }
}

/**
 *  @class Vuppetmaster
 */
class Vuppetmaster {
  /** static singelton instance */
  private static _instance: Vuppetmaster;
  public static create() {
    this._instance = new Vuppetmaster();
  }
  static get instance(): Vuppetmaster {
    return this._instance;
  }

  public vm: any;

  public init() {
    if (!window.VM) return;

    if (this.vm) {
      this.vm.destroy();
    }

    this.vm = new window.VM.Vuppetmaster({
      app: 'Trainer',
      assetsQuality: 'optimized', // use device dependend assets
    });
  }
}

export default Vuppetmaster;
