

export const actionSetFeedbacks = (feedbacks) => ({
	type: 'SET_FEEDBACKS',
	feedbacks: feedbacks
});

export const actionSetFeedback = (feedback) => ({
	type: 'SET_FEEDBACK',
	feedback: feedback
});

export const actionSetFeedbackSession = (session_uuid) => ({
	type: 'SET_FEEDBACK_SESSION_UUID',
	feedbacksessionuuid: session_uuid
});

