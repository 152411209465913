

export const actionSetCompany = (tut) => ({
	type: 'SET_COMPANY',
	company: tut,
});

export const actionSetCompanies = (tut) => ({
	type: 'SET_COMPANIES',
	companies: tut
});

