import React, { Component } from 'react';

import { Icon, Button, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { translate } from '../../../helpers/translate';
import HelpModal from './../../1_atom/HelpModal/HelpModal';
import PauseModal from '../../1_atom/PauseModal/PauseModal';

import styles from './ControlPanel.module.scss';

import { actionSetPause } from '../../../redux/actions/actionViewControl';

import store from '../../../redux/store';

class ControlPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openHelp: false,
    };
  }

  pause(flag) {
    this.props.onPause(flag);
    store.dispatch(actionSetPause(flag));
  }

  render() {
    const {
      module,
      showPause,
      projectProgress,
      showProgress,
      language,
      pause,
      showExit,
      onExit,
    } = this.props;
    const { openHelp } = this.state;

    const project = module && module.project;
    if (!project) return null;

    const projectProgressValue =
      projectProgress < 0.04 ? 0.04 : projectProgress;

    return (
      <div className={pause && !openHelp ? styles.mainpause : styles.main}>
        {showProgress && (
        <div
          className={styles.progressup}
          style={{
            width: projectProgressValue * 100 + '%',
            backgroundColor: 'orange',
          }}
        ></div>)}
        <div className={styles.panel}>
          <div className={styles.buttons}>
            {showPause && !pause && (
              <Popup
                content="Pause"
                trigger={
                  <Button
                    color={'blue'}
                    size={'medium'}
                    circular
                    icon={'pause'}
                    onClick={() => {
                      this.pause(true);
                    }}
                  />
                }
              />
            )}
            {showExit && onExit && (
              <Popup
                content={translate('back', language)}
                trigger={
                  <Button
                    color={'blue'}
                    size={'medium'}
                    circular
                    icon={'log out'}
                    onClick={() => {
                      onExit();
                    }}
                  />
                }
              />
            )}
          </div>
          <div className={styles.progress}></div>
          <div className={styles.help}>
            <Button
              onClick={() => {
                this.pause(true);
                this.setState({ openHelp: true });
              }}
              size={'mini'}
              circular
              icon
            >
              {translate('help', language)}
              <Icon name={'help'} />
            </Button>
          </div>
          <HelpModal
            open={this.state.openHelp}
            onClose={() => {
              this.pause(false);
              this.setState({ openHelp: false });
            }}
          />
        </div>
        {pause && (
          <PauseModal
            onClick={() => {
              this.pause(false);
            }}
          />
        )}
      </div>
    );
  }
}

ControlPanel = connect(
  (state) => ({
    pause: state.viewControl.pause,
    module: state.module.module,
    debug: state.settings.debug,
  }),
  {}
)(ControlPanel);

export default ControlPanel;
