import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Results.module.scss';
import { Dropdown, Button, Segment, Flag, Message } from 'semantic-ui-react';

import { connect } from 'react-redux';

import store from '../../../redux/store';

import RestAPI, { member } from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import { actionSetCompany } from '../../../redux/actions/actionCompany';
import { actionSetResults } from '../../../redux/actions/actionResult';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: null,
    };
  }

  updateView() {
    RestAPI.members();
  }

  render() {
    const { companies, company, modules, members, results } = this.props;

    const validCompanies = companies || [];
    const optCompanies = arrayDropDown(validCompanies, 'all');

    const rows =
      results &&
      members &&
      results.map((result, index) => {
        const member =
          members &&
          members.find((member) => member.uuid === result.member_uuid);
        const module =
          modules &&
          modules.find((module) => module.uuid === result.module_uuid);

        return {
          result,
          member: member && member.name,
          module: module && module.name,
          created: result.created,
          finished: result.finished?'👍':'',
          completed: result.completed,
          score: result.score,
          scoreMin: result.scoreMin,
          scoreMax: result.scoreMax,
        };
      });

    const columns = [
      {
        name: 'Member',
        key: 'member',
        required: true,
        singleLine: true,
      },
      {
        name: 'Module',
        key: 'module',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Demo',
        key: 'demo',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'Finished',
        key: 'finished',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Completed',
        key: 'completed',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Score',
        key: 'score',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'ScoreMin',
        key: 'scoreMin',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'ScoreMax',
        key: 'scoreMax',
        disabled: true,
        singleLine: true,
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Results:</h3>
        <Message>
          Company:
          {validCompanies && validCompanies.length > 0 && (
            <Dropdown
              placeholder="Filter Company"
              search
              value={company ? company.uuid : 'undefined'}
              selection
              options={optCompanies}
              onChange={(e, d) => {
                const company = companies.find(
                  (company) => company.uuid === d.value
                );
                RestAPI.changeCompany(company && company.uuid).then(() => {
                  RestAPI.membersByCompany(company.uuid).then(
                    async (members) => {
                      let proms = [];
                      members.forEach((member) => {
                        proms.push(RestAPI.resultsByMember(member.uuid));
                      });
                      let results = await Promise.all(proms);
                      let array = [];
                      results.forEach(entry => {
                        array = [...entry,...array];
                      })
                      store.dispatch(actionSetResults(array));
                    }
                  );
                  RestAPI.members();
                  store.dispatch(actionSetCompany(company));
                });
              }}
            />
          )}
        </Message>
        <div>
          {rows ? (
            <DataTable
              tableProps={{ selectable: false, compact: true, celled: true }}
              name="assignemnts"
              placeholder="Filter name"
              sortable={true}
              columns={columns}
              rows={rows}
              updateView={() => this.updateView()}
            />
          ) : (
            <div>Select Company</div>
          )}
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Results = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    companies: state.company.companies,
    company: state.company.company,
    results: state.result.results,
    modules: state.module.modules,
  }),
  {} // bind account loading action creator
)(Results);

export default withRouter(Results);
