import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import './index.scss';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter } from 'react-router-dom'
import MainPage from './components/4_views/Main/MainPage';
import store from './redux/store';

ReactDOM.render(
	(
		<Provider store={store}>
			<BrowserRouter basename={'/' + process.env.REACT_APP_DEPLOY}>
				<MainPage />
			</BrowserRouter>
		</Provider>
	), document.getElementById('root'));


