import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Sessions.css';
import moment from 'moment';

import { Checkbox, Table, Button, Segment } from 'semantic-ui-react';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { actionSetSession } from '../../../redux/actions/actionSession';

import { connect } from 'react-redux';
import store from '../../../redux/store';
import RestAPI from '../../../RestAPI';
var clone = require('clone');

class Session extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editmode: false,
      projects: {},
    };
  }

  render() {
    const {
      login,
      session,
      members,
      modules,
      vmprojects,
    } = this.props;

    const { editmode } = this.state;

    const isRoot = scopeIncludesOneOf(login && login.scope, ['root']);

    let member = members && members.find((m) => m.uuid === session.member_uuid);
    let module = modules && modules.find((m) => m.uuid === session.module_uuid);

    let vmproject =
      module &&
      module.storybuilder_key &&
      vmprojects &&
      vmprojects
        .find((project) => project.key === module.storybuilder_key)

    if (!session) return null;

    let duration = '';
    if (session.modified) {
      let start = moment(session.created);
      let end = moment(session.modified);
      duration = moment.duration(end.diff(start)).humanize();
    }


    let sequencelist =
      session.sequences &&
      module &&
      vmproject &&
      session.sequences.map((sequence) => {
        let sequenceObj = vmproject.data.sequences.find(
          (seq) => seq.uuid === sequence.uuid
        );
        if (sequenceObj)
          return (
            <Table.Row key={sequence.uuid}>
              <Table.Cell>{sequenceObj.name}</Table.Cell>
              <Table.Cell>{isRoot && sequence.uuid}</Table.Cell>
            </Table.Row>
          );
        else
          return (
            <Table.Row key={sequence.uuid}>
              <Table.Cell>unkown</Table.Cell>
            </Table.Row>
          );
      });

    return (
      session && (
        <div>
          <h3>Session:</h3>
          <br />
          UUID: {session.uuid}
          <br />
          member: {member && member.name}
          <br />
          module: {module && module.name}
          <br />
          created:{' '}
          {session.created
            ? moment(session.created).format('DD:MM:YYYY HH:mm:ss')
            : '-'}
          <br />
          duration: {duration}
          <br />
          end: {session.status.end ? '👍' : '❌'}
          <br />
          finished: {session.status.finished ? '👍' : '❌'}
          <br />
          scoreCurrent: {session.scoreCurrent}
          <br />
          scoreMin: {session.scoreMin}
          <br />
          scoreMax: {session.scoreMax}
          <br />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>UUID</Table.HeaderCell>
                <Table.HeaderCell>UUID</Table.HeaderCell>
                <Table.HeaderCell>UUID</Table.HeaderCell>
                <Table.HeaderCell>UUID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {sequencelist && <Table.Body>{sequencelist}</Table.Body>}
          </Table>
          <pre>
            ip:
            <br />
            {session && JSON.stringify(session.clientdata, null, 4)}
            <br />
          </pre>
          {isRoot && (
            <Checkbox
              label="Edit Mode"
              toggle
              checked={editmode}
              onChange={(event, data) => {
                this.setState({ editmode: data.checked });
              }}
            />
          )}
          {editmode && (
            <Segment inverted color={'red'}>
              <Checkbox
                label="Finished"
                toggle
                checked={session.status.finished}
                onChange={(event, data) => {
                  let newSession = clone(session);
                  newSession.status.finished = data.checked;
                  store.dispatch(actionSetSession(newSession));
                }}
              />
              <br />
              <Button
                onClick={() => {
                  RestAPI.updateSession(session);
                }}
              >
                Apply
              </Button>
            </Segment>
          )}
        </div>
      )
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Session = connect(
  (state) => ({
    login: state.login.login,
    session: state.session.session,
    members: state.member.members,
    modules: state.module.modules,
    vmprojects: state.vuppetmaster.vmprojects,
  }),
  {} // bind account loading action creator
)(Session);

export default withRouter(Session);
