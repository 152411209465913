import React from 'react';
import styles from './AspectRatio.module.scss';

import useDimensions from 'react-cool-dimensions';

const AspectRatio = ({ children, ratio = 16/9 }) => {
  const { observe, width, height } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });
  let newWidth = width;
  let newHeight = height;
  const currentRatio = width / height;
  let marginLeft = 0;
  let marginTop = 0;
  if (currentRatio < ratio) {
    newHeight = width / ratio;
    marginTop = (height - newHeight) / 2;
  } else {
    newWidth = height * ratio - 10;
    marginLeft = (width - newWidth) / 2;
  }

  return (
    <div ref={observe} className={styles.main}>
      <div
        style={{
          position: 'absolute',
          width: newWidth + 'px',
          height: newHeight + 'px',
          marginLeft: marginLeft + 'px',
          marginTop: marginTop + 'px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AspectRatio;
