const initialState = {
  start_sequenceuuid: null,
  current_sequenceuuid: null,
  vmprojects: [],
  vmproject: null,
  messages: [],
  message: null,
};

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADDMESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    case 'SET_START_SEQUENCEUUID':
      return {
        ...state,
        start_sequenceuuid: action.start_sequenceuuid,
      };
    case 'SET_CURRENT_SEQUENCEUUID':
      return {
        ...state,
        current_sequenceuuid: action.current_sequenceuuid,
      };
    case 'SET_VMPROJECTS':
      return {
        ...state,
        vmprojects: action.vmprojects,
      };
    default:
      return state;
  }
};

export default reducerMain;
