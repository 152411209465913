import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Sessions.css';
import moment from 'moment';

import {
  Segment,
  Icon,
  Form,
  Button,
  Accordion,
  Dropdown,
  Message,
} from 'semantic-ui-react';

import { connect } from 'react-redux';

import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';

import { actionSetSession } from '../../../redux/actions/actionSession';
import { actionSetCompany } from '../../../redux/actions/actionCompany';
import { arrayDropDown } from '../../../helpers/arrayDropDown';

class Sessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      filterName: '',
    };
    RestAPI.modules();
    RestAPI.members();
    if (this.props.company) this.updateView(this.props.company.uuid);
  }

  async updateView(company_uuid) {
    await RestAPI.sessionsByCompanyModule(
      company_uuid ? company_uuid : undefined
    );
  }

  onSelect(session) {
    if (session) {
      store.dispatch(actionSetSession(session));

      this.props.history.push('/admin/session/' + session.uuid);
    }
  }

  onImportSessions(newSessions) {
    if (newSessions) {
      newSessions.forEach((session) => {
        if (!session.clientdata) session.clientdata = {};
        session.clientdata.ip = 'offline';
        // create session
        RestAPI.createSession(session).then((result) => {
          if (result) {
            RestAPI.updateSessionReplaceUUID(result.uuid, session).then(
              (res) => {
                console.log('ok');
              }
            );
          }
        });
      });
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const {
      sessions,
      members,
      modules,
      companies,
      company,
      login,
      user,
      vmprojects,
    } = this.props;

    const { activeIndex, filterName } = this.state;

    const isAdmin = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);
    const isUser = scopeIncludesOneOf(login && login.scope, ['user']);

    let validCompanies = [];
    if (isUser) {
      if (companies && user && user.companies_uuid)
        validCompanies = user.companies_uuid
          .map((uuid) => {
            return companies.find((company) => company.uuid === uuid);
          })
          .filter((company) => company);
    } else if (companies) {
      validCompanies = companies;
    }

    const optCompanies = arrayDropDown(validCompanies, 'all');

    const sessionsAll =
      sessions &&
      sessions.map((session) => {
        let member =
          members && members.find((m) => m.uuid === session.member_uuid);
        let module =
          modules && modules.find((m) => m.uuid === session.module_uuid);
        let company =
          module &&
          module.company_uuid &&
          companies &&
          companies.find((c) => c.uuid === module.company_uuid);

        return {
          member: member,
          module: module,
          company: company,
          session: session,
        };
      });

    const rows =
      sessionsAll &&
      sessionsAll
        .filter(
          (s) =>
            filterName === '' ||
            (filterName !== '' &&
              s.member &&
              s.member.name.startsWith(filterName))
        )
        .map((s) => {
          let session = s.session;

          let duration = '';
          if (session.modified) {
            let start = moment(session.created);
            let end = moment(session.modified);
            duration = moment.duration(end.diff(start)).humanize();
          }

          let finished = '';
          if (session && session.status.end) {
            finished = '👎';
          }
          if (session.status.finished) {
            finished = '👍';
          }

          // get last
          const vmproject =
            s.module &&
            s.module.storybuilder_key &&
            vmprojects &&
            vmprojects.find(
              (project) => project.key === s.module.storybuilder_key
            );
            const sequencelist =
            session.sequences &&
            s.module &&
            vmproject &&
            session.sequences.map((sequence) => {
              let sequenceObj = vmproject.data.sequences.find(
                (seq) => seq.uuid === sequence.uuid
              );
              return sequenceObj;
            }) || [];
          const lastSeq = sequencelist.length > 0 && sequencelist[sequencelist.length - 1]; 

          let client = '';
          if (session.clientdata && session.clientdata.ip) {
            if (session.clientdata.ip === 'offline') client = 'offline';
            else if (session.clientdata.ip) client = session.clientdata.ip.city;
          }

          let pages = '';
          if (session.sequences) {
            // new sequences
            pages = session.sequences.length;
          }

          let row = {
            uuid: session.uuid,
            session: session,
            member: s.member ? s.member.name : '',
            module: s.module ? s.module.name : '',
            created: session.created,
            duration: duration,
            finished: finished,
            pages,
            company: s.company ? s.company.name : '',
            client: client,
            lastSeq: lastSeq && lastSeq.name,
            score: session && session.scoreCurrent,
            min: session && session.scoreMin,
            max: session && session.scoreMax,
          };
          return row;
        });

    const columns = [
      {
        name: 'Member',
        key: 'member',
        required: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Module',
        key: 'module',
        required: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'Duration',
        key: 'duration',
        disabled: true,
        singleLine: true,
        type: 'duration',
      },
      {
        name: 'Finished',
        key: 'finished',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Client',
        key: 'client',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Pages',
        key: 'pages',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'LastSeq',
        key: 'lastSeq',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Score',
        key: 'score',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Min',
        key: 'min',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Max',
        key: 'max',
        disabled: true,
        singleLine: true,
      },
      !company && {
        name: 'Company',
        key: 'company',
        disabled: true,
        singleLine: true,
      },
    ];

    const actions = [
      {
        icon: 'external',
        color: 'blue',
        tooltip: 'show session',
        func: (row) => this.onSelect(row.session),
      },
    ];

    return (
      <div>
        <h3>Sessions:</h3>
        <Message>
          Company:
          {validCompanies && validCompanies.length > 1 && (
            <Dropdown
              placeholder="Filter Company"
              value={company ? company.uuid : 'undefined'}
              selection
              options={optCompanies}
              onChange={(e, d) => {
                store.dispatch(
                  actionSetCompany(
                    companies.find((company) => company.uuid === d.value)
                  )
                );
                this.updateView(d.value);
              }}
            />
          )}
        </Message>{' '}
        <div id="sessions">
          <DataTable
            name="sessions"
            placeholder="Filter name"
            sortable={true}
            columns={columns}
            actions={actions}
            rows={rows}
            updateView={() => this.updateView()}
          />

          <Segment>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                Filter
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <Form size="tiny">
                  <Form.Group widths="equal">
                    <Form.Input
                      label="Member"
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="Member Name"
                      onChange={(d, e) =>
                        this.setState({ filterName: e.value })
                      }
                    />
                  </Form.Group>
                </Form>
              </Accordion.Content>
            </Accordion>
          </Segment>
        </div>
      </div>
    );
  }
}

Sessions = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    sessions: state.session.sessions,
    members: state.member.members,
    modules: state.module.modules,
    companies: state.company.companies,
    company: state.company.company,
    vmprojects: state.vuppetmaster.vmprojects,
  }),
  {}
)(Sessions);

export default withRouter(Sessions);
