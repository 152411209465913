import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Users.module.scss';

import { connect } from 'react-redux';

import {
  actionSetUser,
  actionSetUsers,
} from '../../../redux/actions/actionUser';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import NewRowModal from '../../3_organisms/NewRowModal/NewRowModal';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: null,
    };
  }

  updateView() {
    store.dispatch(actionSetUsers(null));
    RestAPI.users();
    RestAPI.members();
  }

  onSelect(user) {
    store.dispatch(actionSetUser(user));
  }

  onAddNew(name) {
    let user = {
      name: name,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    RestAPI.createUser(user).then((res) => {
      if (res && !res.error) {
        this.updateView();
      }
    });
  }

  onDelete(user) {
    RestAPI.deleteUser(user).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetUser(null));
        this.updateView();
      }
    });
  }

  onSet(user) {
    if (user) {
      store.dispatch(actionSetUser(user));
      this.props.history.push('/admin/user/' + user.uuid);
    }
  }

  render() {
    const { companies, users, members, login } = this.props;

    const { showAddModal, userToDelete } = this.state;

    const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);

    const rows =
      users &&
      members && 
      users.map((user, index) => {
        const companyNames =
          companies &&
          user.companies_uuid &&
          user.companies_uuid.map((uuid) => {
            const company = companies.find((company) => company.uuid === uuid);
            return company ? company.name : 'unkown';
          });
        return {
          role: user.role,
          name: user.name,
          email: user.email,
          companies: companyNames && companyNames.join(' '),
          user: user,
        };
      });

    const actions = [
      canEdit && {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit user',
        func: (row) => this.onSet(row.user),
      },
    ];

    const actionsMenu = [
      canEdit && {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit user',
        func: (row) => this.onSet(row.user),
      },
      canEdit && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete user',
        func: (row) => this.setState({ userToDelete: row.user }),
      },
    ];

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
      },
      {
        name: 'Email',
        key: 'email',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Companies',
        key: 'companies',
        disabled: true,
        singleLine: false,
      },
      {
        name: 'Role',
        key: 'role',
        disabled: true,
        singleLine: true,
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Users:</h3>
        <div>
          <DataTable
            tableProps={{selectable:false, compact: true, celled: true}}
            name="users"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            onSelect={(row) => this.onSelect(row.user)}
            generalActions={[
              canEdit && {
                icon: 'add',
                text: 'new user',
                color: 'blue',
                float: 'right',
                func: (event) => this.setState({ showAddModal: true }),
              },
            ]}
            updateView={() => this.updateView()}
          />
        </div>
        <ModalOkCancel
          text="Delete User?"
          color="red"
          open={userToDelete != null}
          onOK={() => {
            this.onDelete(userToDelete);
            this.setState({ userToDelete: null });
          }}
          onCancel={() => this.setState({ userToDelete: null })}
        />
        {showAddModal && (
          <NewRowModal
            icon={'add'}
            title={'new user'}
            fields={columns}
            onApply={(result) => {
              this.onAddNew(result.name);
              this.setState({ showAddModal: false });
            }}
            onValid={(name, column) => {
              if (column === 'name') {
                return (
                  !users.find((user) => user.name === name) &&
                  !members.find((member) => member.name === name)
                );
              } else return true;
            }}
            onCancel={() => this.setState({ showAddModal: false })}
          />
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Users = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    companies: state.company.companies,
  }),
  {} // bind account loading action creator
)(Users);

export default withRouter(Users);
