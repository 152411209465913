import React from 'react';

import styles from './Copyright.module.scss';
import { translate } from './../../../helpers/translate';

const Copyright = ({ showLink = true, language = 'de' }) => {
  return (
    <div className={styles.main}>
      <div className={styles.copyright}>
        {translate('copyright', language)}
        {showLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.virtual-trainer.de"
          >
            {' – '}
            www.virtual-trainer.de
          </a>
        )}
      </div>
      <div className={styles.imprint}>
        {showLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.virtual-trainer.de/impressum"
          >
            {translate('imprint', language)}
          </a>
        )}
      </div>
    </div>
  );
};

export default Copyright;
