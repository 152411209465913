import React, { Component } from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../../redux/store';

import styles from './Ticket.module.scss';

import RestAPI, { modules } from '../../../RestAPI';
import { actionSetSession } from '../../../redux/actions/actionSession';
import {
  actionSetModule,
  actionSetLanguage,
} from '../../../redux/actions/actionModule';
import { actionSetStartSequenceUUID } from '../../../redux/actions/actionVuppetmaster';

import { translate } from '../../../helpers/translate';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { allLanguages } from '../../../helpers/alllanguages';
import Copyright from '../../1_atom/Copyright/Copyright';
import HelpModal from '../../1_atom/HelpModal/HelpModal';
import LanguageButton from '../../1_atom/LanguageButton/LanguageButton';
import MediaQuery from 'react-responsive';
import './../../../custom.css';
import QRCodeSizeable from '../../1_atom/QRCodeSizeable/QRCodeSizeable.js';
import Vuppetmaster from '../../../helpers/VuppetMaster';

const { detect } = require('detect-browser');
const browser = detect();

const clone = require('clone');
const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

const minWidth = 650;

const LoadingModules = () => (
  <div>
    <Icon size={'large'} name="circle notch" loading></Icon>
  </div>
);

const SelectLanguage = (props) => {
  return (
    <div className="selectlanguage">
      {props.languages.map((lang) => {
        const entry = allLanguages.find((entry) => entry.code === lang.code);
        return (
          <LanguageButton
            key={lang.code}
            active={lang.code === props.active}
            code={lang.code}
            label={entry && entry.original ? entry.original : entry.text}
            onClick={() => {
              props.onLanguage(lang.code);
            }}
          />
        );
      })}
    </div>
  );
};

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_modules: null,
      selected: false,
      languages: [],
      language: 'de',
      member_uuid: null,
      isDemoMember: false,
      error: null,
      code: null,
    };
  }

  async componentDidMount() {
    this.setState({ selected: false });

    if (!this.props.member) {
      return;
    }

    //
    let member_uuid = this.props.member.uuid;

    if (this.props.member.language)
      this.setState({ language: this.props.member.language });

    let clientdata = {};
    if (md.mobile()) {
      clientdata.mobile = {
        phone: md.phone(),
        tablet: md.tablet(),
        userAgent: md.userAgent(),
        os: md.os(),
        isiphone: md.is('iPhone'),
      };
    }

    clientdata.browser = {
      name: browser.name,
      version: browser.version,
      os: browser.os,
      ip: null,
    };

    // create a new session
    let newSession = {
      version: '2.0.0',
      module_uuid: null,
      sequences: null,
      member_uuid: member_uuid,
      user_uuid: null,
      status: {
        finished: false,
        end: false,
      },
      scoreMin: 0,
      scoreMax: 0,
      scoreCurrent: 0,
      variables: null,
      clientdata: clientdata,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    const result = await RestAPI.createSession(newSession);
    if (result) {
      newSession.uuid = result.uuid;
      store.dispatch(actionSetSession(newSession));
    }

    let languages = [];
    const setLanguages = (module) => {
      if (module.languages) {
        // set languages
        module.languages
          .filter((lang) => lang.active)
          .forEach((lang) => {
            if (!languages.find((entry) => entry.code === lang.code)) {
              languages.push(lang);
            }
          });
      }
      this.setState({ languages });
    };

    let resultModules = [];

    //
    // Get member modules
    //
    resultModules = await RestAPI.modulesByMember(this.props.member.uuid);
    if (resultModules && resultModules.ok && resultModules.data) {
      let member_modules = [];

      resultModules.data.forEach((module) => {
        module.finished = false;

        if (module.sessions) {
          // if a finished session exists
          let finishedSession = module.sessions.find(
            (s) => s.finished || (s.status && s.status.finished)
          );
          if (finishedSession) {
            module.finished = true;
          }
        }
        setLanguages(module);
        if (module.status === 'active') member_modules.push(module);
      });
      this.setState({ member_modules: member_modules });

      // check languages
      if (!languages && languages.length > 0) {
        if (this.state.language !== languages[0].code)
          this.setState({ language: languages[0].code });
      }

      if (member_modules.length > 0) {
        const firstModule = member_modules[0];
        if (firstModule.finished) {
          const res = await RestAPI.resultsByMemberAndModule(
            this.props.member.uuid,
            firstModule.uuid
          );

          this.setState({ code: res.code });
        }
      }
    }

    let id = setInterval(() => {
      if (this.props.ipdata && this.props.session && Vuppetmaster.instance.vm) {
        clearInterval(id);
        let newSession = clone(this.props.session);
        newSession.clientdata.ip = this.props.ipdata;
        if (!newSession.info) newSession.info = [];
        if (typeof Vuppetmaster.instance.vm === 'string') {
          this.setState({
            error: `Error Code 34 (${Vuppetmaster.instance.vm})`,
          });
          newSession.info.push({ error: Vuppetmaster.instance.vm });
        }
        RestAPI.updateSession(newSession);
      }
    }, 300);
  }

  onLogout() {
    RestAPI.logout();
    this.props.history.push('/login');
  }

  async onStart(module) {
    this.setState({ selected: true });

    // check if vuppetmaster is loaded
    if (!Vuppetmaster.instance.vm) {
      return;
    }

    let sequences = null;

    if (module.storybuilder_key) {
      sequences = [];
      store.dispatch(actionSetModule(module));
      store.dispatch(actionSetLanguage(this.state.language));
    }

    if (module.finished) {
      // no need to make a tutorial
      this.props.history.push('/nothingtodo');
      return;
    }

    let variables = [];
    let scoreCurrent = 0;

    let name = this.props.member.name;

    if (name && !this.state.isDemoMember) {
      // find last existen and NOT ended session
      if (module.sessions && module.sessions.length > 0) {
        let lastSession = module.sessions
          .reverse()
          .find((session) => session.sequences && session.sequences.length > 0);
        if (lastSession) {
          scoreCurrent = lastSession.scoreCurrent;
          let lastSequence = lastSession.sequences.pop();
          store.dispatch(actionSetStartSequenceUUID(lastSequence.uuid));
        }
      }
    }

    let newSession = clone(this.props.session);

    // fill new session
    newSession.module_uuid = module.uuid;
    newSession.sequences = sequences;
    newSession.member_uuid = this.props.member.uuid;
    newSession.status = {
      end: false,
      finished: false,
    };

    newSession.language = this.state.language;

    newSession.scoreCurrent = scoreCurrent;

    newSession.created = new Date().toISOString();
    newSession.variables = variables;
    newSession.clientdata.ip = this.props.ipdata;
    store.dispatch(actionSetSession(newSession));

    RestAPI.updateSession(newSession).then((result) => {
      this.props.history.push('/trainer2');
    });
  }

  render() {
    const { selected, member_modules, language, languages, error, code } =
      this.state;
    const { member } = this.props;

    const modules =
      member_modules &&
      member_modules
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .filter((module) => {
          if (module.languages)
            return module.languages.find(
              (entry) => entry.active && entry.code === language
            );
          else return true;
        });

    const modulesRows =
      modules &&
      modules.map((module, index) => {
        // Name of the module
        let label = module.name;
        if (!label && module.labels) {
          // use sb label
          if (module.labels[language] !== undefined) {
            label = module.labels[language];
            label = label.replace(/&amp;/g, '&');
          }
        }
        if (label.length === 0) {
          return <div key={index}></div>;
        }

        return (
          <div className={styles.modulebutton} key={index}>
            <Button
              fluid
              color={'blue'}
              icon
              labelPosition="right"
              disabled={module.finished || selected}
              onClick={() => {
                if (!error) this.onStart(module);
              }}
            >
              {label}
              {module.finished && (
                <Icon size={'large'} color={'green'} name="check" />
              )}
            </Button>
          </div>
        );
      });

    const showLoading = !modulesRows;

    return (
      <div className={styles.main}>
        <div className={styles.language}>
          {languages.length > 1 && (
            <SelectLanguage
              active={language}
              languages={languages}
              onLanguage={(language) => {
                this.setState({ language });
              }}
            />
          )}
        </div>
        <div className={styles.helpbutton}>
          <Button
            size={'mini'}
            icon
            onClick={() => {
              this.setState({ openHelp: true });
            }}
          >
            {translate('help', language)}
            <Icon name={'help'} />
          </Button>
        </div>

        <MediaQuery style={{ height: '100%' }} minWidth={minWidth}>
          {code ? (
            <div className={styles.qrcode}>
              <QRCodeSizeable value={member.link} />
              <h1>
                Pin:
                {code}
              </h1>
            </div>
          ) : (
            <div className={styles.flex_container}>
              <div className={styles.flex_item1}>
                <div className={styles.image}>
                  <Image
                    src={
                      process.env.PUBLIC_URL + '/assets/gloria_walkpresent.png'
                    }
                  />
                </div>
              </div>
              <div className={styles.flex_item2}>
                <div className={styles.select}>
                  <h1>
                    {translate('welcome1', language)}
                    <p />
                    {translate('welcome2', language)}
                  </h1>
                  <p />
                  <h3>{translate('title', language)}</h3>
                  <p />
                  {showLoading ? <LoadingModules /> : modulesRows}
                  <p />
                  <p />
                  <div className={styles.logout}>
                    <Button onClick={() => this.onLogout()}>
                      {translate('logout', language)}
                    </Button>
                  </div>
                </div>{' '}
              </div>
            </div>
          )}
        </MediaQuery>
        <MediaQuery maxWidth={minWidth - 1}>
          {code ? (
            <div className={styles.qrcode}>
              <QRCodeSizeable value={member.link} />
              <h1>
                Pin:
                {code}
              </h1>
            </div>
          ) : (
            <div className={styles.select_mobile}>
              <h1>
                {translate('welcome1', language)}
                <p />
                {translate('welcome2', language)}
              </h1>
              <p />
              <h3>{translate('title', language)}</h3>
              <p />
              {showLoading ? <LoadingModules /> : modulesRows}
              <div className={styles.logout}>
                <Button onClick={() => this.onLogout()}>
                  {translate('logout', language)}
                </Button>
              </div>
            </div>
          )}
        </MediaQuery>

        <div className={styles.footer}>
          <Copyright language={language} />
        </div>
        <HelpModal
          open={this.state.openHelp}
          onClose={() => {
            this.setState({ openHelp: false });
          }}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Ticket = connect(
  (state) => ({
    login: state.login.login,
    ipdata: state.login.ipdata,
    session: state.session.session,
    member: state.member.member,
    modules: state.module.modules,
    debug: state.settings.debug,
  }),
  {} // bind account loading action creator
)(Ticket);

export default withRouter(Ticket);
