import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './ModuleResult.module.scss';

import DataTable from '../../3_organisms/DataTable/DataTable';

import RestAPI from '../../../RestAPI';
import { Button, Icon } from 'semantic-ui-react';

class ModuleResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
    };

    this.updateView();
  }

  updateView() {
    RestAPI.members().then((result) => {});
    RestAPI.evalSessions(this.props.company.uuid, this.props.module.uuid).then(
      (results) => {
        this.setState({ results });
      }
    );
  }

  onExportExcel() {
    RestAPI.evalDownloadSessionsAsXLSX(
      this.props.company.uuid,
      this.props.module.uuid,
      `${this.props.company.name}-${this.props.module.name}`
    );
  }

  render() {
    const { module } = this.props;
    const { results } = this.state;

    const rows =
      results &&
      results.map((result) => {
        return {
          member: result.member_name,
          finished: result.finished,
          completed: result.completed,
          score: `(${result.scoreCurrent} / ${result.scoreMax})`,
          date: result.date,
        };
      });

    const columns = [
      {
        name: 'Member',
        key: 'member',
        singleLine: true,
      },
      {
        name: 'Finished',
        key: 'finished',
        singleLine: true,
      },
      {
        name: 'Score',
        key: 'score',
        singleLine: true,
      },
      {
        name: 'Completed',
        key: 'completed',
        singleLine: true,
      },
      {
        name: 'Date',
        key: 'date',
        singleLine: true,
        type: 'date',
      },
    ];

    return (
      <div className={styles.main}>
        <h1>Result: {module.name} </h1>
        <DataTable
          name="moduleresult"
          sortable={true}
          columns={columns}
          rows={rows}
        />
        <Button
          icon
          onClick={() => {
            this.onExportExcel();
          }}
        >
          <Icon name="download" />
          Export Excel
        </Button>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
ModuleResult = connect(
  (state) => ({
    login: state.login.login,
    module: state.module.module,
    members: state.member.members,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(ModuleResult);

export default withRouter(ModuleResult);
