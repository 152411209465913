const labels = {
  welcome1: {
    de: 'Herzlich Willkommen',
    en: 'Welcome',
    it: 'Benvenuto, benvenuti',
    fr: 'Bienvenue',
    es: 'Bienvenido',
    pt: 'Bem-vindo',
    pl: 'Witamy',
    nl: 'Hartelijk welkom',
    tr: 'Hoş geldiniz',
    ru: 'Добро пожаловать',
    ro: 'Bine ati venit',
    jp: '温かい歓迎',
  },
  welcome2: {
    de: 'zu Ihrer Schulung',
    en: 'to your training',
    it: 'alla vostra istruzione',
    fr: 'à votre formation',
    es: 'a su instrucción',
    pt: 'à sua formação.',
    pl: 'na szkoleniu',
    nl: 'bij uw training.',
    tr: 'Öğretilerinizi',
    ru: 'на инструктаж',
    ro: 'la antrenamentul dvs',
    jp: '研修への参加を歓迎します。',
  },
  title: {
    de: 'Ihre Unterweisungen:',
    en: 'Your instructions:',
    it: 'Vostre istruzioni:',
    fr: 'Vos formations:',
    es: 'Sus instrucciones:',
    pt: 'Suas formações:',
    pl: 'Twoje nauki:',
    nl: 'Uw trainings:',
    tr: 'Öğretilerinizi:',
    ru: 'Твои учения:',
    ro: 'Învățăturile tale',
    jp: 'あなたのトレーニング。',
  },
  help: {
    de: 'Hilfe',
    en: 'Help',
    it: 'Aiuto',
    fr: 'Aide',
    es: 'Ayuda',
    pt: 'Ajuda',
    pl: 'Pomoc',
    nl: 'Hulp',
    tr: 'Yardım',
    ru: 'помощь',
    ro: 'Ajutor',
    jp: 'ヘルプ',
  },
  module_empty: {
    de: 'Zur Zeit sind keine Unterweisungen für Sie vorhanden',
    en: 'At the moment there are no instructions for you',
    it: 'Al momento non ci sono istruzioni per te',
    fr: 'Pour le moment il n`y a pas d`instructions pour vous',
    es: 'Por el momento no hay instrucciones para ti.',
    pt: 'No momento não há instruções para você',
    pl: 'W tej chwili nie ma dla Ciebie instrukcji',
    nl: 'Op dit moment zijn er geen instructies voor u',
    tr: 'Şu anda sizin için herhangi bir talimat yok',
    ru: 'На данный момент для вас нет инструкции',
    ro: 'Momentan nu există instrucțiuni pentru tine',
    jp: '現時点では、あなたへの指示はありません',
  },
  loading: {
    de: 'Einen Moment noch',
    en: 'One moment please',
    it: 'Un momento, per favore',
    fr: 'Un instant, s´il vous plaît',
    es: 'Un momento, por favor',
    pt: 'Um momento, por favor',
    pl: 'Jedną chwilę, proszę.',
    nl: 'Een moment, alstublieft',
    tr: 'Bir saniye lütfen',
    ru: 'минутку, пожалуйста',
    ro: 'Un moment va rog',
    jp: '少々お待ち下さい',
  },
  rotate: {
    de: 'Bitte drehen',
    en: 'Please rotate',
    it: '',
    fr: '',
    es: '',
    pt: '',
    pl: '',
    nl: '',
    tr: '',
    ru: '',
    ro: '',
    jp: '',
  },
  logout: {
    de: 'Abmelden',
    en: 'Logout',
    it: 'Disconnettere',
    fr: 'Déconnecter',
    es: 'Desconectar',
    pt: 'Desconectar',
    pl: 'Wylogować się',
    nl: 'Uitloggen',
    tr: 'Çıkış yap',
    ru: 'выходить из системы',
    ro: 'Deconectează',
    jp: 'ログオフ',
  },
  start: {
    de: 'Training starten',
    en: 'Start now',
    it: 'Avviare l´istruzione',
    fr: 'Commencez la formation',
    es: 'Comienza la instrucción',
    pt: 'Iniciar a formação',
    pl: 'Start szkoleniowy',
    nl: 'Start de training',
    tr: 'Talimatı başlatın',
    ru: 'Начало тренировок',
    ro: 'Începeți antrenamentul',
    jp: 'トレーニング開始',
  },
  back: {
    de: 'Zurück',
    en: 'Back',
  },
  rating_header_0: {
    de: 'Umfrage',
    en: 'Survey',
    it: 'Sondaggio',
    fr: 'Sondage',
    es: 'Sondeo',
    pt: 'Sondagem',
    pl: 'Ankieta',
    nl: 'Enquête',
    tr: 'Anket',
    ru: 'опрос',
    ro: 'Studiu',
    jp: '視聴率',
  },
  rating_header_1: {
    de:
      'Wir würden uns über eine Bewertung dieser Unterweisung freuen. Ein Stern ist schlecht und fünf Sterne sind sehr gut. Nach Klicken auf Absenden werden Sie ausgeloggt.',
    en:
      'We would be pleased to receive an evaluation of this instruction. One star is bad and five stars are very good. After clicking on send you will be logged out.',
    it:
      'Saremmo lieti di ricevere una valutazione di questo insegnamento. Una stella è cattiva e cinque stelle sono molto buone. Dopo aver cliccato su invia sarai disconnesso.',
    fr:
      'Nous serions heureux de recevoir une évaluation de cet enseignement. Une étoile est mauvaise et cinq étoiles sont très bonnes. Après avoir cliqué sur le bouton transmettre, vous serez déconnecté.',
    es:
      'Estaríamos encantados de recibir una evaluación de esta enseñanza. Una estrella es mala y cinco estrellas son muy buenas. Después de hacer clic en enviar, se cerrará la sesión.',
    pt:
      'Teríamos todo o prazer em receber uma avaliação deste ensino. Uma estrela é má e cinco estrelas são muito boas. Depois de clicar em transmitir, será desconectado.',
    pl:
      'Chętnie otrzymamy ocenę tego nauczania. Jedna gwiazda jest zła i pięć gwiazdek jest bardzo dobra. Po kliknięciu na przycisk Wyślij, zostaniesz wylogowany.',
    nl:
      'We zouden graag een evaluatie van dit onderwijs ontvangen. Eén ster is slecht en vijf sterren zijn erg goed. Na het klikken op submit wordt u uitgelogd.',
    tr:
      'Bu talimatın bir değerlendirmesini almaktan mutluluk duyarız. Bir yıldız kötü ve beş yıldız çok iyi. Gönder´i tıkladıktan sonra çıkış yapacaksınız.',
    ru:
      'Мы были бы рады получить оценку этого учения. Одна звезда плохая и пять звезд очень хорошие. После нажатия кнопки Отправить вы выйдете из системы.',
    ro:
      'Am fi bucuroși să primim o evaluare a acestei instrucțiuni. O stea este rea și cinci stele sunt foarte bune. După ce faceți clic pe Trimiteți, veți fi deconectat.',
    jp:
      'この教えを評価していただければ幸いです。星1つは悪くて星5つはとても良いです。送信をクリックするとログアウトされます。',
  },
  rating_0: {
    de: 'Wie zufrieden waren Sie mit der Schulung?',
    en: 'How satisfied were you with the training?',
    it: 'Quanto è rimasto soddisfatto della formazione?',
    fr: 'Dans quelle mesure avez-vous été satisfait de la formation?',
    es: '¿Qué tan satisfecho estabas con el entrenamiento?',
    pt: 'Qual foi o seu grau de satisfação com a formação?',
    pl: 'Jak bardzo byłeś zadowolony z tego szkolenia?',
    nl: 'Hoe tevreden was u met de training?',
    tr: 'Eğitimden ne kadar memnun kaldınız?',
    ru: 'Насколько вы были удовлетворены тренировкой?',
    ro: 'Cât de mulțumit ați fost de antrenament?',
    jp: '研修の満足度はいかがでしたか？',
  },
  rating_1: {
    de: 'Wie hilfreich waren die Inhalte für Sie?',
    en: 'How helpful was the content for you?',
    it: 'Quanto è stato utile il contenuto per voi?',
    fr: 'Dans quelle mesure le contenu vous a-t-il été utile?',
    es: '¿Qué tan útil fue el contenido para usted?',
    pt: 'Qual foi a utilidade do conteúdo para si?',
    pl: 'Jak bardzo pomocna była dla ciebie treść?',
    nl: 'Hoe nuttig was de inhoud voor u?',
    tr: 'İçerik size ne kadar yardımcı oldu?',
    ru: 'Насколько полезным было для вас содержание?',
    ro: 'Cât de util ți-a fost conținutul?',
    jp: 'あなたにとってどれだけ参考になる内容でしたか？',
  },
  rating_send: {
    de: 'Absenden',
    en: 'Send',
    it: 'Invia',
    fr: 'Transmettre',
    es: 'Enviar',
    pt: 'Transmitir',
    pl: 'Wyślij',
    nl: 'Submit',
    tr: 'Göndermek',
    ru: 'Отправить',
    ro: 'Trimite',
    jp: '送る',
  },
  confirm0: {
    de: 'Bevor Sie das Training starten!',
    en: 'Before you start the training!',
    it: 'Prima di iniziare l´allenamento!',
    fr: 'Avant de commencer à vous former!',
    es: '¡Antes de empezar a entrenar!',
    pt: 'Antes de começar a treinar!',
    pl: 'Zanim zaczniesz trenować!',
    nl: 'Voordat je begint met trainen!',
    tr: 'Eğitime başlamadan önce!',
    ru: 'Перед началом тренировки!',
    ro: 'Înainte de a începe antrenamentul!',
    jp: 'トレーニングを始める前に!',
  },
  confirm1: {
    de:
      'Bitte bestätigen Sie, dass Sie berechtigt sind, die Schulung zu absolvieren (Identitätsbestätigung).',
    en:
      'Please confirm that you are authorized to attend the training (identity confirmation).',
    it:
      'Si prega di confermare che si ha diritto a partecipare al corso di formazione (conferma dell´identità).',
    fr:
      'Veuillez confirmer que vous êtes autorisé à assister à la formation (confirmation d´identité).',
    es:
      'Por favor, confirme que está autorizado a asistir al entrenamiento (confirmación de identidad).',
    pt:
      'Por favor confirmar que está autorizado a frequentar a formação (confirmação de identidade).',
    pl:
      'Proszę potwierdzić, że jesteście Państwo uprawnieni do udziału w szkoleniu (potwierdzenie tożsamości).',
    nl:
      'Gelieve te bevestigen dat u bevoegd bent om de opleiding bij te wonen (bevestiging van uw identiteit).',
    tr:
      'Lütfen eğitimi tamamlamaya yetkili olduğunuzu onaylayın (kimlik onayı).',
    ru:
      'Пожалуйста, подтвердите, что вы имеете право присутствовать на тренинге (подтверждение личности).',
    ro:
      'Vă rugăm să confirmați că sunteți autorizat să finalizați instruirea (confirmarea identității).',
    jp: '研修に参加する権利があることを確認してください（本人確認）。',
  },
  confirm2: {
    de:
      'Hiermit bestätige ich, dass ich Inhaber der Zugangsdaten für das Lernmodul bin und mir persönlich diese Daten (Login, Passwort) ausgehändigt wurden. Ich bestätige, dass ich allein berechtigt bin, das Lernmodul zu absolvieren. Mir ist bewusst, dass ich für fehlerhafte Angaben und Bestätigungen entsprechend haftbar gemacht werden kann!',
    en:
      'I hereby confirm that I am the owner of the access data for the learning module and that I have personally received this data (login, password). I confirm that I alone am authorized to complete the learning module. I am aware of the fact that I can be held responsible for incorrect information and confirmations!',
    it:
      'Confermo di essere il proprietario dei dati di accesso al modulo di apprendimento e di aver ricevuto personalmente questi dati (login, password). Confermo che solo io ho il diritto di completare il modulo di apprendimento. Sono consapevole del fatto che posso essere ritenuto responsabile per informazioni e conferme errate!',
    fr:
      'Je confirme par la présente que je suis le propriétaire des données d´accès au module d´apprentissage et que j´ai personnellement reçu ces données (login, mot de passe). Je confirme que je suis le seul à pouvoir suivre le module d´apprentissage. Je suis conscient du fait que je peux être tenu pour responsable en cas d´informations et de confirmations incorrectes!',
    es:
      'Por la presente confirmo que soy el propietario de los datos de acceso al módulo de aprendizaje y que he recibido personalmente estos datos (login, contraseña). Confirmo que sólo yo tengo derecho a completar el módulo de aprendizaje. ¡Soy consciente del hecho de que puedo ser responsable de información y confirmaciones incorrectas!',
    pt:
      'Confirmo que sou o proprietário dos dados de acesso ao módulo de aprendizagem e que recebi pessoalmente estes dados (login, palavra-passe). Confirmo que só eu tenho o direito de completar o módulo de aprendizagem. Estou consciente de que posso ser responsabilizado por informações e confirmações incorrectas!',
    pl:
      'Niniejszym potwierdzam, że jestem właścicielem danych dostępowych do modułu nauki i że dane te otrzymałem osobiście (login, hasło). Potwierdzam, że tylko ja jestem upoważniony do ukończenia modułu edukacyjnego. Jestem świadomy tego, że mogę być pociągnięty do odpowiedzialności za błędne informacje i potwierdzenia!',
    nl:
      'Ik bevestig hierbij dat ik de eigenaar ben van de toegangsgegevens voor de leermodule en dat ik deze gegevens persoonlijk heb ontvangen (login, wachtwoord). Ik bevestig dat alleen ik het recht heb om de leermodule in te vullen. Ik ben me bewust van het feit dat ik aansprakelijk kan worden gesteld voor onjuiste informatie en bevestigingen!',
    tr:
      'Öğrenme modülü için erişim verilerinin sahibi olduğumu ve bu verileri şahsen aldığımı (giriş, şifre) onaylıyorum. Yalnızca öğrenme modülünü tamamlamaya yetkili olduğumu onaylıyorum. Yanlış bilgi ve onaylardan sorumlu tutulabileceğimin farkındayım!',
    ru:
      'Настоящим я подтверждаю, что я являюсь владельцем данных для доступа к учебному модулю и что я лично получил эти данные (логин, пароль). Я подтверждаю, что только я имею право завершить учебный модуль. Я осознаю, что могу нести ответственность за неправильную информацию и подтверждения!',
    ro:
      'Prin prezenta confirm că sunt proprietarul datelor de acces pentru modulul de învățare și că am primit personal aceste date (autentificare, parolă). Confirm că sunt autorizat exclusiv să finalizez modulul de învățare. Sunt conștient că pot fi considerat responsabil pentru informații și confirmări incorecte!',
    jp:
      '私はここに、私が学習モジュールのアクセスデータの所有者であり、個人的にこのデータ（ログイン、パスワード）を受信したことを確認します。私は、私だけが学習モジュールを完了する権利があることを確認します。誤った情報や確認事項で責任を問われる可能性があることを認識しています!',
  },
  imprint : {
    de: 'Impressum',
    en: 'Imprint'
  },
  copyright: {
    de: '© VIRTUAL TRAINER ist ein Produkt der Charamel GmbH',
    en: '© VIRTUAL TRAINER is a produkt of Charamel GmbH',
    it: '© VIRTUAL TRAINER è un prodotto della Charamel GmbH',
    fr: '© VIRTUAL TRAINER est un produit de Charamel GmbH',
    es: '© VIRTUAL TRAINER es un producto de Charamel GmbH',
    pt: '© VIRTUAL TRAINER é um produto da Charamel GmbH',
    pl: '© VIRTUAL TRAINER jest produktem firmy Charamel GmbH',
    nl: '© VIRTUAL TRAINER is een product van Charamel GmbH',
    tr: '© VIRTUAL TRAINER, Charamel GmbH´nin bir ürünüdür',
    ru: '© VIRTUAL TRAINER является продуктом компании Charamel GmbH.',
    ro: '© VIRTUAL TRAINER este un produs de Charamel GmbH',
    jp: '© VIRTUAL TRAINER は Charamel GmbH の製品です。',
  },
};

export const translate = (id, lang) => {
  let label = labels[id];
  if (!label) label = labels['en'];
  if (!label) return '';

  const text = label[lang];
  return text ? text : '';
};
