const initialState = {
  //	loginUser: null,
  loginMember: null,

  activePath: '',

  scripts: [],

  users: [],
  user: null,

  themes: [],

  members: [],
  member: null,
};

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_REFRESH':
      return {
        ...state,
        users: state.users,
      };
    case 'SET_PATH':
      return {
        ...state,
        activePath: action.activePath,
      };
    case 'SET_VARIABLES':
      return {
        ...state,
        variables: action.variables,
      };
    case 'SET_THEMES':
      return {
        ...state,
        themes: action.themes,
      };
    case 'SET_SCRIPTS':
      return {
        ...state,
        scripts: action.scripts,
      };
    default:
      return state;
  }
};

export default reducerMain;
