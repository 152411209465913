import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Companies.css';
import moment from 'moment';

import { connect } from 'react-redux';

import {
  actionSetCompany,
  actionSetCompanies,
} from '../../../redux/actions/actionCompany';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';
import ModalNewCompany from './ModalNewCompany.js';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateView() {
    store.dispatch(actionSetCompanies(null));
    RestAPI.companies();
  }

  onSelect(company) {
    store.dispatch(actionSetCompany(company));
  }

  async onAddNew(name, memberName, modules) {
    let company = {
      name: name,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
      active: true,
    };

    let result = await RestAPI.createCompany(company);
    if (result && result.error) {
      return;
    }

    if (memberName) {
      result = await RestAPI.company(result.uuid);
      if (result && !result.error) {
        company = result.data.companies[0];
        this.createDemoMembers(company, memberName, modules);
      }
    }

    this.updateView();
    return;
  }

  async createDemoMembers(company, memberName, modules) {
    // create modules
    for (let module of modules) {
      if (module.active) {
        const mod = {
          ...module,
          company_uuid: company.uuid,
        };
        delete mod.active;
        delete mod.min;
        delete mod.max;
        delete mod.tags;
        const res = await RestAPI.createModule(mod);
        if (res) {
          module.uuid = res.uuid;
        }
      }
    }

    for (const module of modules) {
      if (module.active) {
        for (let tag of module.tags) {
          // create Assignment
          await RestAPI.createModuleAssignment({
            company_uuid: company.uuid,
            module_uuid: module.uuid,
            created: new Date().toISOString(),
            member_tag: tag,
          });
        }
      }
    }

    // create members
    const fahrer = [0, 1, 2];
    const lager = [0, 1, 2, 3, 4];

    for (let i = 0; i < 10; i++) {
      let tags = [];
      if (fahrer.includes(i)) tags.push('Fahrer');
      if (lager.includes(i)) tags.push('Lager');

      const member = {
        name: memberName + i,
        passwd: '',
        company_uuid: company.uuid,
        tags,
        passive: false,
      };
      const memberResult = await RestAPI.createMember(member);
      if (memberResult && memberResult.data.members_create.uuid) {
        for (const module of modules) {
          if (module.active) {
            if (
              (fahrer.includes(i) && module.tags.includes('Fahrer')) ||
              (lager.includes(i) && module.tags.includes('Lager')) ||
              module.tags.includes('all')
            ) {
              // create results

              // 0: not started
              // 1: started
              // 2: finished
              const resultMode = Math.floor(Math.random() * 3);
              let score = 0;
              let finished = false;
              let completed = 0;
              switch (resultMode) {
                case 0:
                  score = 0;
                  finished = false;
                  completed = 0;
                  break;
                case 1:
                  score = module.min - 1;
                  finished = false;
                  completed = score / module.max;
                  break;
                case 2:
                  score = module.max;
                  finished = true;
                  completed = 1;
                  break;
                default:
                  break;
              }

              const result = {
                member_uuid: memberResult.data.members_create.uuid,
                module_uuid: module.uuid,
                completed,
                finished,
                score,
                scoreMax: module.max,
                scoreMin: module.min,
                modified: new Date().toISOString(),
              };
              await RestAPI.createResult(result);
            }
          }
        }
      }
    }

    this.updateView();
  }

  onDelete(company) {
    RestAPI.deleteCompany(company).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetCompany(null));
        this.updateView();
      }
    });
  }

  onSet(company) {
    if (company) {
      store.dispatch(actionSetCompany(company));
      this.props.history.push('/admin/company/' + company.uuid);
    }
  }

  render() {
    const { companies, user, login } = this.props;

    const { showAddModal, companyToDelete } = this.state;

    const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);
    const isUser = scopeIncludesOneOf(login && login.scope, ['user']);

    let validCompanies = [];
    if (isUser) {
      if (companies && user && user.companies_uuid)
        validCompanies = user.companies_uuid
          .map((uuid) => {
            return companies.find((company) => company.uuid === uuid);
          })
          .filter((company) => company);
    } else if (companies) validCompanies = companies;

    const rows =
      validCompanies &&
      validCompanies.map((company, index) => ({
        name: company.name,
        active: company.active ? '✔️' : '❌',
        company: company,
        created: company.created
          ? moment(company.created).format('DD:MM:YYYY HH:mm:ss')
          : '-',
        modified: company.modified
          ? moment(company.modified).format('DD:MM:YYYY HH:mm:ss')
          : '-',
      }));

    const actions = [
      canEdit && {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit company',
        func: (row) => this.onSet(row.company),
      },
    ];

    const actionsMenu = [
      canEdit && {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit company',
        func: (row) => this.onSet(row.company),
      },
      canEdit && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete company',
        func: (row) => this.setState({ companyToDelete: row.company }),
      },
    ];

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Modified',
        key: 'modified',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Active',
        key: 'active',
        disabled: true,
        singleLine: true,
      },
    ];

    return (
      <div>
        <h3>Companies:</h3>
        <div id="companies">
          <DataTable
            name="companies"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            onSelect={(row) => this.onSelect(row.company)}
            generalActions={[
              canEdit && {
                icon: 'add',
                text: 'new company',
                color: 'blue',
                float: 'right',
                func: (event) => this.setState({ showAddModal: true }),
              },
            ]}
            updateView={() => this.updateView()}
          />
        </div>
        <ModalOkCancel
          text="Delete Company?"
          color="red"
          open={companyToDelete != null}
          onOK={() => {
            this.onDelete(companyToDelete);
            this.setState({ companyToDelete: null });
          }}
          onCancel={() => this.setState({ companyToDelete: null })}
        />
        {showAddModal && (
          <ModalNewCompany
            onOk={({ name, memberName, modules }) => {
              this.onAddNew(name, memberName, modules);
              this.setState({ showAddModal: false });
            }}
            onCancel={() => this.setState({ showAddModal: false })}
          />
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Companies = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(Companies);

export default withRouter(Companies);
