
import React from 'react';
import './HelpModal.css';
import { Modal, Segment, Header, Button } from 'semantic-ui-react'

class HelpModal extends React.Component {
	render() {

		return (
			<Modal size={'small'} open={this.props.open}>
				<Header icon='help' content='Hilfe' />
				<Modal.Content>
					<Segment>
						<h1>
							Sie brauchen Unterstützung?
					</h1>
						Rufen Sie uns an oder schreiben Sie uns eine E-Mail:
					<br />
						<br />
						<b>Charamel GmbH - Service-Zeit: 8:00-17:00 Uhr</b>
						<br />
						Telefon: 	0221-3366-80
					<br />
						E-Mail:		support@virtual-trainer.de
				</Segment>
					<Button onClick={() => { this.props.onClose(); }} > ok </Button>
				</Modal.Content>
			</Modal>
		)
	}
}

export default HelpModal;
