const initialState = {
  pause: false,
};

const reducerViewControl = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAUSE':
      return {
        ...state,
        pause: action.pause,
      };
    default:
      return state;
  }
};

export default reducerViewControl;
