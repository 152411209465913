/*************************************************************************
 *
 * 	Settings
 *
 * **********************************************************************/

class Settings {
  public static _feedbackWithScreenShot = false;
}

export { Settings };
