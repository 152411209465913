import React, { Component } from 'react';

import { Rating, Button, Message } from 'semantic-ui-react';
import { translate } from '../../../helpers/translate';

import styles from './RatingView.module.scss';

class RatingView extends Component {
  constructor(props) {
    super(props);

    const table = [
      translate('rating_0', this.props.language),
      translate('rating_1', this.props.language),
    ];

    this.state = {
      table,
      rating: table.map(() => null),
    };
  }

  render() {
    const { onClick } = this.props;
    const { rating, table } = this.state;

    const rows = table.map((entry, index) => {
      return (
        <tr key={index}>
          <td>{entry}</td>
          <td>
            <Rating
              size={'huge'}
              icon="star"
              defaultRating={3}
              maxRating={5}
              onRate={(event, data) => {
                let newRating = [...rating];
                newRating[index] = data.rating;
                this.setState({ rating: newRating });
              }}
            />
          </td>
        </tr>
      );
    });

    return (
      <div className={styles.main}>
        <Message>
          <h1 style={{textAlign:'center'}}>{translate('rating_header_0', this.props.language)}</h1>
          <p />
          {translate('rating_header_1', this.props.language)}
          <p/>
          <table>
            <tbody>{rows}</tbody>
          </table>
          <div className={styles.button}>
            <Button
              onClick={() => {
                onClick(rating);
              }}
            >
              {translate('rating_send', this.props.language)}
            </Button>
          </div>
        </Message>
      </div>
    );
  }
}

export default RatingView;
