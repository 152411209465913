

export const actionSetSessions = (sessions) => ({
	type: 'SET_SESSIONS',
	sessions: sessions
});

export const actionSetSession = (session) => ({
	type: 'SET_SESSION',
	session: session,
});
