import React, { Component } from 'react';
import { Button, Form, Message, Icon, Image } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';

import { withRouter } from 'react-router-dom';

import styles from './Login.module.scss';
import './../../../../custom.css';

import HelpModal from '../../../1_atom/HelpModal/HelpModal';
import Copyright from '../../../1_atom/Copyright/Copyright';
import RestAPI from '../../../../RestAPI';
import { StatefulPinInput } from 'react-input-pin-code';

const iconError = 'exclamation triangle';
const iconCheck = 'circle notched';
const iconOk = 'check';

const maxWidth = 500;

const ViewForm = (props) => {
  switch (props.mode) {
    case 'code':
      let vcode = props.code;
      while (vcode.length < 8) vcode += ' ';

      return (
        <div>
          <h1>
            Herzlich Willkommen
            <p />
            zu Ihrer Schulung!
          </h1>
          <p />
          <h4>
            Bitte loggen Sie sich mit dem Ihnen zugewiesenen achtstelligen Code
            ein:
          </h4>
          <p />
          <Form size={props.size}>
            <StatefulPinInput
              length={8}
              type="text"
              size="lg"
              format={(char) => char.toUpperCase()}
              borderColor={'rgb(255,0,0)'}
              values={(vcode || '        ').split('')}
              onChange={(value, index, values) => {
                console.log(value, index, values);
                if (values.length < 8) values.push(' ');
                props.onChange('code', values.join(''));
              }}
            />{' '}
            <br />
            <Button
              size={props.size}
              color={'blue'}
              disabled={!props.active}
              fluid
              onClick={() => props.onCode()}
            >
              Login
            </Button>
            <Message
              hidden={props.message === ''}
              icon
              negative={props.icon === iconError}
              positive={props.icon === iconOk}
            >
              <Icon name={props.icon} loading={props.icon === iconCheck} />
              <Message.Content>
                <Message.Header>{props.message}</Message.Header>
              </Message.Content>
            </Message>
            <br />
          </Form>
        </div>
      );
    case 'forgotInput':
      return (
        <div>
          <p>
            Vielen Dank für Ihre "Passwort vergessen"-Anfrage. Falls Ihre
            E-Mail-Adresse bei uns registriert ist, erhalten Sie in Kürze eine
            E-Mail von uns. Falls Sie sich mit Nutzernamen ohne E-Mail-Adresse
            anmelden, erhalten Sie keine E-Mail von uns. Wenden Sie sich in
            diesem Fall bitte an Ihre Vorgesetzten. Vielen Dank.
          </p>
          <p>
            Geben Sie Ihre E-Mail in das untenstehende Formular ein, um Ihr
            Passwort zurückzusetzen.
          </p>
          <Form size={props.size}>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email"
              onChange={(d, e) => props.onChange('email', e.value)}
            />
            <Button
              size={props.size}
              disabled={!props.active}
              color={'blue'}
              fluid
              onClick={() => props.onSendForgot()}
            >
              Absenden
            </Button>
          </Form>
        </div>
      );
    case 'forgotMessage':
      return (
        <div>
          <p>
            Sie erhalten eine E-Mail. Bitte prüfen Sie auch Ihren Spam-Ordner.
            Sollte die E-Mail nicht sofort eintreffen, warten Sie bitte ein paar
            Minuten. Sollten Sie keine E-Mail erhalten, wenden Sie sich in
            diesem Fall bitte an Ihre Vorgesetzten.
          </p>

          <div style={{ textAlign: 'center' }}>
            <Button
              size={props.size}
              color={'blue'}
              disabled={!props.active}
              onClick={() => props.onCloseForgot()}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    case 'login':
      return (
        <div>
          <h1>
            Herzlich Willkommen
            <p />
            zu Ihrer Schulung!
          </h1>
          <p />
          Bitte loggen Sie sich mit den Ihnen vorliegenden Zugangsdaten ein:
          <p />
          <Form size={props.size}>
            {false && (
              <Form.Input
                fluid
                icon="home"
                iconPosition="left"
                placeholder="Firmen-ID"
                onChange={(d, e) => props.onChange('company', e.value)}
              />
            )}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Login-Name"
              onChange={(d, e) => props.onChange('user', e.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Passwort"
              type="password"
              onChange={(d, e) => props.onChange('passwd', e.value)}
            />
            <Button
              size={props.size}
              color={'blue'}
              disabled={!props.active}
              fluid
              onClick={() => props.onLogin()}
            >
              Login
            </Button>
            <Message
              hidden={props.message === ''}
              icon
              negative={props.icon === iconError}
              positive={props.icon === iconOk}
            >
              <Icon name={props.icon} loading={props.icon === iconCheck} />
              <Message.Content>
                <Message.Header>{props.message}</Message.Header>
              </Message.Content>
            </Message>
            <br />
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();

                props.onPasswdForgot();
              }}
            >
              Passwort vergessen
            </a>
          </Form>
        </div>
      );

    default:
      return <div>undefined</div>;
  }
};

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      passwd: '',
      email: '',
      company: '',
      code: '',
      message: '',
      showIcon: false,
      showMessage: '',
      openHelp: false,
      buttonactive: true,
      mode: props.mode ? props.mode : 'login',
    };
  }

  onSendForgot() {
    RestAPI.forgotEmail(this.state.email);
    this.setState({ mode: 'forgotMessage' });
  }

  onChange(type, data) {
    switch (type) {
      case 'code':
        this.setState({ code: data });
        break;
      case 'email':
        // todo: chris check email

        this.setState({ email: data, buttonactive: true });
        break;
      case 'company':
        this.setState({ company: data });
        break;
      case 'user':
        this.setState({ user: data });
        break;
      case 'passwd':
        this.setState({ passwd: data });
        break;
      default:
        break;
    }
  }

  onCode() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Überprüfen',
    });
    RestAPI.code(this.state.code)
      .then((res) => {
        if (process.env.NODE_ENV !== 'production') console.log(res);
        if (res) {
          if (res.ok) {
            this.setState({ showMessage: 'ok', showIcon: iconOk });
            setTimeout(() => {
              // show the nice thump 200 ms
              this.props.onLogin(res);
            }, 200);
          } else if (res.fetchError) {
            this.setState({
              showMessage:
                'Verbindungsfehler. Bitte versuchen Sie es später erneut.',
              showIcon: iconError,
              buttonactive: true,
            });
          } else {
            console.error('invalid user or password');
            this.setState({
              showMessage: 'Ungültige Zugangsdaten',
              showIcon: iconError,
              buttonactive: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage:
            'Verbindungsfehler. Bitte versuchen Sie es später erneut.',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }
  onLogin() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Überprüfen',
    });
    RestAPI.login(this.state.company, this.state.user, this.state.passwd)
      .then((res) => {
        if (process.env.NODE_ENV !== 'production') console.log(res);
        if (res) {
          if (res.ok) {
            this.setState({ showMessage: 'ok', showIcon: iconOk });
            setTimeout(() => {
              // show the nice thump 200 ms
              this.props.onLogin(res);
            }, 200);
          } else if (res.fetchError) {
            this.setState({
              showMessage:
                'Verbindungsfehler. Bitte versuchen Sie es später erneut.',
              showIcon: iconError,
              buttonactive: true,
            });
          } else {
            console.error('invalid user or password');
            this.setState({
              showMessage: 'Ungültige Zugangsdaten',
              showIcon: iconError,
              buttonactive: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage:
            'Verbindungsfehler. Bitte versuchen Sie es später erneut.',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item1}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + '/assets/gloria_walkpresent.png'}
            />
          </div>
          <div className={styles.flex_item2}>
            <div className={styles.loginform}>
              <MediaQuery minDeviceWidth={maxWidth}>
                <ViewForm
                  code={this.state.code}
                  mode={this.state.mode}
                  onCloseForgot={() => {
                    this.setState({ mode: 'login' });
                  }}
                  onSendForgot={() => {
                    this.onSendForgot();
                  }}
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onCode={() => {
                    this.onCode();
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  onPasswdForgot={() => {
                    this.setState({ mode: 'forgotInput', buttonactive: false });
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={maxWidth - 1}>
                <ViewForm
                  mode={this.state.mode}
                  size={'mini'}
                  onCloseForgot={() => {
                    this.setState({ mode: 'login' });
                  }}
                  onSendForgot={() => {
                    this.onSendForgot();
                  }}
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  onPasswdForgot={() => {
                    this.setState({ mode: 'forgotInput' });
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </MediaQuery>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Copyright />
        </div>

        <div className={styles.helpbutton}>
          <Button
            icon
            size={'mini'}
            onClick={() => {
              this.setState({ openHelp: true });
            }}
          >
            Hilfe
            <Icon name={'help'} />
          </Button>
        </div>
        <HelpModal
          open={this.state.openHelp}
          onClose={() => {
            this.setState({ openHelp: false });
          }}
        />
      </div>
    );
  }
}

export default withRouter(LoginForm);
