
const sampleModules = [
  {
    name: 'Sicherung',
    languages: [
      {
        code: 'de',
        active: true,
      },
      {
        code: 'pl',
        active: true,
      },
      {
        code: 'ro',
        active: true,
      },
    ],
    labels: {
      de: 'Sicherung',
      name: 'module_label',
    },
    confirmUserID: true,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: 'G8HBU-HXU79-HTE6R-JL2GB',
    storybuilder_name: 'charamel_webinar_sicherung_01',
    active: false,
    min: 4,
    max: 5,
    tags: ['Fahrer'],
  },
  {
    name: 'UVV - Custom Logo',
    languages: [
      {
        code: 'de',
        active: true,
      },
      {
        code: 'en',
        active: true,
      },
    ],
    labels: {
      de: 'UVV - Custom Logo',
      name: 'module_label',
    },
    confirmUserID: true,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: 'VT6C5-JNYE4-8ARPA-1WYL5',
    storybuilder_name: 'vt_uvv_09_infml_02',
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
  {
    name: 'Umwelt',
    languages: [
      {
        code: 'de',
        active: true,
      },
    ],
    labels: {
      de: 'Standard Umwelt 2021',
      name: 'module_label',
    },
    confirmUserID: null,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: '1FTDA-G8U8B-72T3U-JH84Y',
    storybuilder_name: 'charamel_standard_umwelt_fml_01',
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
  {
    name: 'Brandschutz',
    languages: [
      {
        code: 'de',
        active: true,
      },
    ],
    labels: {
      de: 'Standard Brandschutz 2021',
      name: 'module_lbl',
    },
    confirmUserID: null,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: 'B7MLH-EZVA3-UV5NT-6MHD3',
    storybuilder_name: 'charamel_standard_brandschutz_fml_01',
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
  {
    name: 'Arbeitsschutz',
    languages: [
      {
        code: 'de',
        active: true,
      },
    ],
    labels: {
      de: 'Standard Arbeitssicherheit 2021',
      name: 'module_lbl',
    },
    confirmUserID: null,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: '5XD22-ZXE2L-B649B-A4TAT',
    storybuilder_name: 'charamel_standard_arbeitssicherheit_allgemein_fml_06',
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
  {
    name: 'Datenschutz',
    languages: [
      {
        code: 'de',
        active: true,
      },
      {
        code: 'en',
        active: true,
      },
      {
        code: 'fr',
        active: true,
      },
      {
        code: 'es',
        active: true,
      },
      {
        code: 'pl',
        active: true,
      },
      {
        code: 'ru',
        active: true,
      },
    ],
    labels: {},
    confirmUserID: null,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: 'ZT2KK-L1FXV-XXUUM-XQVNM',
    storybuilder_name: 'charamel_standard_datenschutz_fml_02',
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
  {
    name: 'Gabelstapler',
    languages: [
      {
        code: 'de',
        active: true,
      },
    ],
    labels: {
      de: 'Standard Gabelstapler 2021',
      name: 'module_lbl',
    },
    confirmUserID: null,
    showPause: true,
    showProgress: true,
    status: 'active',
    storybuilder_key: 'C3ZTE-BEC3T-CYZUQ-5JDNW',
    storybuilder_name: 'charamel_standard_gabelstapler_fml_02',
    active: false,
    min: 4,
    max: 5,
    tags: ['Fahrer','Lager']
  },
  {
    name: 'Corona',
    storybuilder_key: 'REBD4-D2UAD-3K2NZ-FZ936',
    status: 'active',
    languages: [
      {
        code: 'de',
        active: true,
      },
      {
        code: 'en',
        active: true,
      },
    ],
    labels: {
      name: 'module_label',
      de: 'Standard Corona 2021',
      en: 'Standard Corona 2021',
    },
    storybuilder_name: 'charamel_standard_corona_fml_01',
    showProgress: true,
    showPause: true,
    confirmUserID: null,
    active: false,
    min: 4,
    max: 5,
    tags: ['all'],
  },
];

export default sampleModules;
