const initialState = {
  points: [],
  color: 'black',
};

const reducerDrawContainer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DRAWCONTAINER_SETPOINTS':
      return {
        ...state,
        points: action.points,
      };
    case 'SET_DRAWCONTAINER_REMOVEPOINTS':
      return {
        ...state,
        points: state.points.filter((_, i) => i !== state.points.length - 1),
      };
    case 'SET_DRAWCONTAINER_COLOR':
      return {
        ...state,
        color: action.color,
      };
    default:
      return state;
  }
};

export default reducerDrawContainer;
