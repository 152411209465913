import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Form,
  TextArea,
  Message,
  Button,
  Dropdown,
  Icon,
  Feed,
  Popup,
  Breadcrumb,
} from 'semantic-ui-react';

import { connect } from 'react-redux';
import moment from 'moment/moment';

import styles from './Feedback.module.scss';

import { actionSetFeedbacks } from '../../../redux/actions/actionFeedback';
import RestAPI, { sessions } from '../../../RestAPI';
import store from '../../../redux/store';
const clone = require('clone');

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: null,
      currentComment: null,
      currentFeedback: null,
      feedbacks: this.props.feedbacks,
      showHistory: null,
    };
  }

  update(feedback) {
    const newFeedbacks = this.state.feedbacks.map((entry) => {
      if (entry.uuid === feedback.uuid) return feedback;
      else return entry;
    });
    this.setState({ feedbacks: newFeedbacks });
    store.dispatch(actionSetFeedbacks(null));
    RestAPI.updateFeedback(feedback).then(() => {
      RestAPI.feedbacks();
    });
  }

  render() {
    const { module, members, users, feedbacksessionuuid, session } = this.props;
    const {
      feedbacks,
      text,
      currentComment,
      currentFeedback,
      showHistory,
    } = this.state;

    if (!feedbacks || !feedbacksessionuuid) return <div></div>;

    const tags = [
      {
        name: 'Finished',
        color: 'green',
        icon: 'check',
      },
      {
        name: 'Marcel',
        icon: 'user',
      },
      {
        name: 'Kathi',
        icon: 'user',
      },
      {
        name: 'Sarah',
        icon: 'user',
      },
      {
        name: 'Christian',
        icon: 'user',
      },
      {
        name: 'VuppetmMaster',
        color: 'red',
        icon: 'bug',
      },
      {
        name: 'Visual',
        color: 'red',
        icon: 'bug',
      },
    ];
    const optTags = tags.map((tag) => {
      return {
        key: tag.name,
        text: tag.name,
        value: tag.name,
      };
    });

    const renderLabel = (label) => {
      const value = tags.find((tag) => tag.name === label.text);
      return {
        color: value && value.color,
        content: label.text,
        icon: value && value.icon,
      };
    };

    const firstFeedback = feedbacks.find(
      (feedback) => feedback.session_uuid === feedbacksessionuuid
    );

    const member =
      firstFeedback &&
      members &&
      members.find((member) => member.uuid === firstFeedback.member_uuid);

    const user =
      firstFeedback &&
      users &&
      users.find((user) => user.uuid === firstFeedback.user_uuid);

    const sessionFeedbbacks = feedbacks
      .filter((feedback) => feedback.session_uuid === feedbacksessionuuid)
      .map((feedback) => {
        // find sequence history
        let history = [];
        if (session && session.sequences && module && module.project) {
          for (const sequence of session.sequences) {
            const completeSeq = module.project.sequences.find(
              (seq) => seq.uuid === sequence.uuid
            );
            history.push({
              key: sequence.uuid,
              content: completeSeq ? completeSeq.name : '',
              link: false,
            });
            if (sequence.feedback_uuid === feedback.uuid) break;
          }
        }
        const status = feedback.status || [];
        return (
          <Message key={feedback.uuid}>
            <div className={styles.form}>
              <h3>Sequence: {feedback.sequence_name}</h3>

              {showHistory && (
                <div>
                  <h4>History:</h4>
                  <Breadcrumb icon="right angle" sections={history} />
                </div>
              )}

              <Message>{text ? text : feedback.text}</Message>
              <div>
                <Dropdown
                  multiple
                  selection
                  fluid
                  options={optTags}
                  placeholder="Choose Tag"
                  renderLabel={renderLabel}
                  value={status}
                  onChange={(e, { value }) => {
                    let f = clone(feedback);
                    f.status = value;
                    this.update(f);
                  }}
                />
              </div>
              <div className={styles.comments}>
                <div>Comments:</div>
                <Feed>
                  {feedback.comments &&
                    feedback.comments.map((comment, index) => {
                      return (
                        <Feed.Event key={index}>
                          <Feed.Label>
                            <Icon name="user" />
                          </Feed.Label>
                          <Feed.Content>
                            <Feed.Summary>
                              <Feed.User>Admin</Feed.User>
                              <Feed.Date>
                                {moment(comment.created).format(
                                  'DD:MM:YYYY HH:mm:ss'
                                )}
                              </Feed.Date>
                            </Feed.Summary>
                            <Feed.Extra text>{comment.text}</Feed.Extra>
                          </Feed.Content>
                        </Feed.Event>
                      );
                    })}
                </Feed>

                {currentComment && currentFeedback.uuid === feedback.uuid && (
                  <div>
                    <Form>
                      <TextArea
                        value={currentComment.text}
                        onChange={(e, { value }) => {
                          const newComment = {
                            ...currentComment,
                            text: value,
                            created: new Date().toISOString(),
                          };
                          this.setState({
                            currentComment: newComment,
                          });
                        }}
                      ></TextArea>
                    </Form>
                  </div>
                )}
                {currentComment ? (
                  <Popup
                    content="apply"
                    trigger={
                      <Button
                        size={'mini'}
                        icon
                        onClick={() => {
                          let f = clone(feedback);
                          if (!f.comments) f.comments = [];
                          f.comments.push(currentComment);
                          this.setState({
                            currentComment: null,
                          });
                          this.update(f);
                        }}
                      >
                        <Icon name="reply" />
                      </Button>
                    }
                  />
                ) : (
                  <Popup
                    content="add new comment"
                    trigger={
                      <Button
                        size={'mini'}
                        icon
                        onClick={() => {
                          this.setState({
                            currentComment: { text: '' },
                            currentFeedback: feedback,
                          });
                        }}
                      >
                        <Icon name="add" />
                      </Button>
                    }
                  />
                )}
              </div>
            </div>
          </Message>
        );
      });

    const memberuser = user ? user : member;

    return (
      <div className={styles.main}>
        <Message>
          <h3>Feedback</h3>
          <table>
            <tbody>
              <tr>
                <td>Author:</td>
                <td>{memberuser && memberuser.name}</td>
              </tr>
              <tr>
                <td>Module:</td>
                <td>{module && module.name}</td>
              </tr>
              <tr>
                <td>SB Project:</td>
                <td>{module && module.storybuilder_name}</td>
              </tr>
              <tr>
                <td>Date:</td>
                <td>
                  {moment(firstFeedback.created).format('DD:MM:YYYY HH:mm:ss')}
                </td>
              </tr>
            </tbody>
          </table>
          <Popup
            content="Show sequence history"
            trigger={
              <Button
                size={'mini'}
                icon
                onClick={() => {
                  this.setState({
                    showHistory: showHistory ? null : true,
                  });
                }}
              >
                <Icon name="history" />
              </Button>
            }
          />
        </Message>
        {sessionFeedbbacks}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Feedback = connect(
  (state) => ({
    feedbacks: state.feedback.feedbacks,
    feedbacksessionuuid: state.feedback.feedbacksessionuuid,
    members: state.member.members,
    users: state.user.users,
    modules: state.module.modules,
    module: state.module.module,
    companies: state.company.companies,
    session: state.session.session,
    login: state.login.login,
  }),
  {}
)(Feedback);

export default withRouter(Feedback);
