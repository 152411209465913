

export const arrayDropDown = (entries, empty) => {

	let array = [];
	if (empty) {
		array.push(
			{
				key: empty ? empty : 'not set',
				text: empty ? empty : 'not set',
				value: 'undefined',
			});
	}

	if (entries) {
		array.push(...entries.map(entry => {
			return (
				{
					key: entry.uuid,
					text: entry.name,
					value: entry.uuid
				}
			)
		}))
	}
	return array;
}

