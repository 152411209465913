


export const actionDrawContainerSetPoints = (points) => ({
	type: 'SET_DRAWCONTAINER_SETPOINTS',
	points: points
});

export const actionDrawContainerRemovePoints = () => ({
	type: 'SET_DRAWCONTAINER_REMOVEPOINTS',
});

export const actionDrawContainerSetColor = (drawColor) => ({
	type: 'SET_DRAWCONTAINER_COLOR',
	color: drawColor
});
