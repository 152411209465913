const initialState = {
  feedbacks: null,
  feedback: null,
  feedbacksessionuuid: null,
  feedbackDrawPoints: [],
  feedbackDrawColor: 'black',
};

const reducerFeedback = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FEEDBACKS':
      return {
        ...state,
        feedbacks: action.feedbacks,
      };
    case 'SET_FEEDBACK':
      return {
        ...state,
        feedback: action.feedback,
      };
    case 'SET_FEEDBACK_SESSION_UUID':
      return {
        ...state,
        feedbacksessionuuid: action.feedbacksessionuuid,
      };
    default:
      return state;
  }
};

export default reducerFeedback;
