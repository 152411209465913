

const initialState = {
	sessions: null,
	session: null
};


const reducerSession = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SESSIONS':
			return {
				...state,
				sessions: action.sessions,
			};
		case 'SET_SESSION':
			return {
				...state,
				session: action.session,
			};
		default:
			return state;
	}
};

export default reducerSession;
