

export const actionSetGrid = (grid) => ({
	type: 'SET_SETTINGS_GRID',
	grid: grid
});

export const actionSetDebug = (debug) => ({
	type: 'SET_SETTINGS_DEBUG',
	debug: debug
});

export const actionSetControl = (control) => ({
	type: 'SET_SETTINGS_CONTROL',
	control: control
});

