import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';
import { Dropdown, Message } from 'semantic-ui-react';
import './Members.css';

import { connect } from 'react-redux';
import {
  actionSetMember,
  actionSetMembers,
} from '../../../redux/actions/actionUser';
import store from '../../../redux/store';

import ModalOkCancel from '../../3_organisms/ModalOkCancel';

import RestAPI from '../../../RestAPI';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import DataTable from '../../3_organisms/DataTable/DataTable';
import NewRowModal from '../../3_organisms/NewRowModal/NewRowModal';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import { actionSetCompany } from '../../../redux/actions/actionCompany';

const InlineDiv = styled.div`
  display: inline-block;
  width: '100px';
`;

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.updateView();
  }

  updateView() {
    store.dispatch(actionSetMembers(null));
    RestAPI.sessionsByCompanyModule();
    if (this.props.login.ok) {
      RestAPI.modules().then((result) => {
        RestAPI.members().then((result) => {
          RestAPI.moduleAssignments().then((result) => {});
        });
      });
    }
  }

  onSelect(member) {
    store.dispatch(actionSetMember(member));
  }

  onAddNewMember(newMember) {
    const member = {
      //			user_uuid: this.props.login.uuid,  todo
      name: newMember.name,
      passwd: newMember.passwd,
      company_uuid: this.props.company.uuid,
    };
    RestAPI.createMember(member).then((result) => {
      this.updateView();
    });
  }

  onDelete(member) {
    RestAPI.deleteMember(member).then(() => {
      this.updateView();
    });
  }

  render() {
    const {
      members,
      companies,
      company,
      sessions,
      login,
      user,
      moduleAssignments,
    } = this.props;

    const { memberToDelete } = this.state;

    const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);
    const isUser = scopeIncludesOneOf(login && login.scope, ['user']);

    let validCompanies = [];
    if (isUser) {
      if (companies && user && user.companies_uuid)
        validCompanies = user.companies_uuid
          .map((uuid) => {
            return companies.find((company) => company.uuid === uuid);
          })
          .filter((company) => company);
    } else if (companies) validCompanies = companies;

    const optCompanies = arrayDropDown(validCompanies);

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
        header: false,
      },
      !isUser && {
        name: 'Company',
        key: 'company',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Modules',
        key: 'modules',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Passive',
        key: 'passive',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Scope',
        key: 'scope',
        disabled: true,
        singleLine: true,
      },
    ];


    const actionsMenu = [
      canEdit && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete member',
        func: (row) => this.setState({ memberToDelete: row.member }),
      },
    ];

    let rows =
      moduleAssignments &&
      members &&
      members
        .filter(
          (member) =>
            !company || (company && member.company_uuid === company.uuid)
        )
        .filter(
          (member) =>
            validCompanies &&
            validCompanies.find((c) => c.uuid === member.company_uuid)
        )
        .map((member) => {
          const company =
            validCompanies &&
            validCompanies.find((c) => c.uuid === member.company_uuid);

          const modules_uuid = moduleAssignments
            .filter((ma) => ma.member_uuid === member.uuid)
            .map((ma) => {
              return ma.module_uuid;
            });

          let modulesFinished =
            sessions &&
            modules_uuid &&
            modules_uuid.filter((module_uuid) => {
              return sessions.find(
                (s) =>
                  s.member_uuid === member.uuid &&
                  s.module_uuid === module_uuid &&
                  s.status.finished
              );
            });

          let fin = modulesFinished && modulesFinished.length;
          let moduleString = '';
          if (modules_uuid)
            moduleString =
              '(' +
              fin +
              '/' +
              modules_uuid.length +
              ') ' +
              (fin === modules_uuid.length ? '👍' : '');

          return {
            name: member.name,
            member: member,
            modules: moduleString,
            company: company ? company.name : 'not set',
            passive: member.passive ? 'true' : '',
            scope: member.scope ? member.scope.join(' ') : '',
          };
        });

    return (
      <div className="members">
        <h3>Members:</h3>
        {validCompanies && validCompanies.length > 0 && (
          <Message>
            <InlineDiv>
              Company:
              <Dropdown
                placeholder="Filter Company"
                value={company ? company.uuid : undefined}
                selection
                options={optCompanies}
                onChange={(e, d) => {
                  const company = companies.find(
                    (company) => company.uuid === d.value
                  );
                  RestAPI.changeCompany(company && company.uuid).then(() => {
                    this.updateView();
                  });
                  store.dispatch(actionSetCompany(company));
                }}
              />
            </InlineDiv>
          </Message>
        )}
        <DataTable
          name="members"
          sortable={true}
          columns={columns}
          actionsMenu={actionsMenu}
          rows={rows}
          onSelect={(row) => this.onSelect(row.member)}
          updateView={() => this.updateView()}
        />

        {
          <ModalOkCancel
            text="Delete Member?"
            color="red"
            open={memberToDelete != null}
            onOK={() => {
              this.onDelete(memberToDelete);
              this.setState({ memberToDelete: null });
            }}
            onCancel={() => this.setState({ memberToDelete: null })}
          />
        }
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Members = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    sessions: state.session.sessions,
    member: state.member.member,
    members: state.member.members,
    moduleAssignments: state.module.moduleAssignments,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(Members);

export default withRouter(Members);
