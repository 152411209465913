import React, { Component } from 'react';
import { Button, Form, Message, Icon, Image } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';

import { withRouter } from 'react-router-dom';

import styles from './Confirm.module.scss';

import Copyright from '../../../1_atom/Copyright/Copyright';
import RestAPI from '../../../../RestAPI';
import { parseQueryString } from '../../../../helpers/URL.helper';

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwd1: '',
      passwd2: '',
      sended: false,
      key: null,
    };
  }

  async componentDidMount() {
    const { key } = this.props.match.params;
    this.setState({ key: key });
  }

  onSendNewPasswd() {
    RestAPI.sendNewPasswd(this.state.passwd1, this.state.key);
    this.setState({ sended: true });
  }

  render() {
    const passwwordRequirements = [
      { re: /[0-9]/, label: 'Eine Zahl' },
      { re: /[a-z]/, label: 'Kleinbuchstabe' },
      { re: /[A-Z]/, label: 'Großbuchstabe' },
      { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Sonderzeichen' },
    ];

    const getStrength = (password) => {
      let multiplier = password.length > 7 ? 0 : 1;

      passwwordRequirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
          multiplier += 1;
        }
      });

      return Math.max(
        100 - (100 / (passwwordRequirements.length + 1)) * multiplier,
        10
      );
    };

    const PasswordRequirement = ({ meets, label }) => {
      return <div>{`${meets ? '✔️' : '❌'} ${label}`}</div>;
    };

    const checks = passwwordRequirements.map((requirement, index) => (
      <PasswordRequirement
        key={index}
        label={requirement.label}
        meets={requirement.re.test(this.state.passwd1)}
      />
    ));

    const strength = getStrength(this.state.passwd1);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    return (
      <div className={styles.main}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item1}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + '/assets/gloria_walkpresent.png'}
            />
          </div>
          <div className={styles.flex_item2}>
            {this.state.sended ? (
              <div>
                <p>Das neue Passwort wird gesetzt.</p>
                <Button
                  color={'blue'}
                  fluid
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                >
                  Login
                </Button>
              </div>
            ) : (
              <div className={styles.loginform}>
                <h1>Neues Passwort setzen:</h1>
                <p />
                <Form error={strength !== 100} success={strength === 100}>
                  <Form.Input
                    fluid
                    value={this.state.passwd1}
                    icon="lock"
                    iconPosition="left"
                    type="password"
                    placeholder="Passwort"
                    onChange={(d, e) => {
                      this.setState({ passwd1: e.value });
                    }}
                  />
                  <Form.Input
                    fluid
                    error={
                      this.state.passwd1 !== this.state.passwd2
                        ? 'Sind nicht identisch'
                        : undefined
                    }
                    value={this.state.passwd2}
                    icon="lock"
                    iconPosition="left"
                    type="password"
                    placeholder="Passwort"
                    onChange={(d, e) => {
                      this.setState({ passwd2: e.value });
                    }}
                  />
                  <Message
                    error
                    header="Das Passwort sollte mindestens 8 Zeichen lang sein, aus Groß- und Kleinbuchstaben, Ziffern sowie Sonderzeichen bestehen"
                    content={checks}
                  />
                  <Message
                    success
                    header="Das Passwort sollte mindestens 8 Zeichen lang sein, aus Groß- und Kleinbuchstaben, Ziffern sowie Sonderzeichen bestehen"
                    content={checks}
                  />
                  <Button
                    disabled={
                      strength !== 100 &&
                      this.state.passwd1 === this.state.passwd2
                    }
                    color={'blue'}
                    fluid
                    onClick={() => {
                      this.onSendNewPasswd();
                    }}
                  >
                    Absenden
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <Copyright />
        </div>
      </div>
    );
  }
}

export default withRouter(Confirm);
