import React, { Component } from 'react';
import { Modal, Segment, Header, Button, Checkbox } from 'semantic-ui-react';

import EditInline from '../../1_atom/EditLine/EditLine';

import sampleModules from './ModalNewCompany.data';

class ModalNewCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'new Company',
      memberName: null,
      modules: sampleModules,
    };
  }

  render() {
    const { open, onOk, onCancel } = this.props;
    const { name, memberName, modules } = this.state;

    return (
      <div style={{ width: '400px' }}>
        <Modal open={true}>
          <Header icon="add" content="Add new Company" />
          <Modal.Content>
            <Segment>
              <EditInline
                label={'Company Name:'}
                text={name}
                onChanged={(text) => this.setState({ name: text })}
              />
              <Checkbox
                label="Demo members"
                toggle
                checked={memberName !== null}
                onChange={(event, data) => {
                  this.setState({ memberName: memberName ? null : name });
                }}
              />

              {memberName && (
                <div>
                  {' '}
                  <EditInline
                    label={'Member Prefix:'}
                    text={memberName}
                    onChanged={(text) => this.setState({ memberName: text })}
                  />
                  {modules &&
                    modules.map((module) => {
                      return (
                        <div key={module.storybuilder_key}>
                          <Checkbox
                            label={module.name}
                            toggle
                            checked={modules.active}
                            onChange={(event, data) => {
                              const newModules = modules.map((entry) => {
                                return {
                                  ...entry,
                                  active:
                                    entry.storybuilder_key ===
                                    module.storybuilder_key
                                      ? !entry.active
                                      : entry.active,
                                };
                              });
                              this.setState({
                                modules: newModules,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              )}
            </Segment>

            <Button
              onClick={() => {
                if (onOk)
                  onOk({
                    name,
                    memberName,
                    modules,
                  });
              }}
            >
              ok{' '}
            </Button>
            <Button
              onClick={() => {
                if (onCancel) onCancel();
              }}
            >
              cancel{' '}
            </Button>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalNewCompany;
