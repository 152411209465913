import React, { Component } from 'react';

import { Icon, Button, Message, Form, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import RestAPI from '../../../RestAPI';
import styles from './FeedbackModal.module.scss';
import { cx } from '../../../helpers/classNames.helper';
import UUID from 'uuid/v4';
import html2canvas from 'html2canvas';
import store from '../../../redux/store';
import {
  actionDrawContainerRemovePoints,
  actionDrawContainerSetColor,
  actionDrawContainerSetPoints,
} from '../../../redux/actions/actionDrawContainer';
import Vuppetmaster from '../../../helpers/VuppetMaster';
import { Settings } from '../../../helpers/Settings';

class FeedbackModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSequence: null,
      text: '',
      thanks: false,
      replaying: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentSequence: Vuppetmaster.instance.vm.trainer.getCurrentSequence(),
    });
    Vuppetmaster.instance.vm.trainer.on('sequence.changed', () => {
      this.setState({
        currentSequence: Vuppetmaster.instance.vm.trainer.getCurrentSequence(),
      });
    });
  }

  componentWillUnmount() {}

  onSend() {
    const media_uuid = UUID();

    Vuppetmaster.instance.vm.renderer.screenShot(async (image) => {
      //create a new canvas
      let newCanvas = window.document.createElement('canvas');
      newCanvas.width = image.width;
      newCanvas.height = image.height;
      let context = newCanvas.getContext('2d');

      context.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        image.width,
        image.height
      );

      html2canvas(this.props.canvas, {
        canvas: newCanvas,
        backgroundColor: null,
        ignoreElements: (elem) => {
          return elem.id === 'vuppetmaster2';
        },
      }).then(() => {
        const image = new Image();
        image.src = newCanvas.toDataURL();
        image.onload = () => {
          RestAPI.uploadHtmlImage(image, media_uuid);
        };
      });
    });

    const data = {
      created: new Date().toISOString(),
      text: this.state.text,
      module_uuid: this.props.module.uuid,
      company_uuid: this.props.module.company_uuid,
      member_uuid: this.props.member && this.props.member.uuid,
      user_uuid: this.props.user && this.props.user.uuid,
      sequence_uuid: this.state.currentSequence.uuid,
      sequence_name: this.state.currentSequence.name,
      language: this.props.language,
      session_uuid: this.props.session.uuid,
      media_uuid,
    };
    RestAPI.createFeedback(data).then((result) => {
      this.props.onSend({
        feedback_uuid: result.uuid,
        sequence_uuid: this.state.currentSequence.uuid,
      });
    });

    this.setState({ text: '' });
    store.dispatch(actionDrawContainerSetPoints([]));
  }

  onGoto(uuid) {
    const seq = Vuppetmaster.instance.vm.trainer.getSequenceByUUID(uuid);
    if (seq) this.props.onGoto(seq); //seq.play();
  }

  onGotoNext() {
    const uuid = Vuppetmaster.instance.vm.trainer.getNextSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
    return uuid ? true : false;
  }

  onGotoPrev() {
    const uuid = Vuppetmaster.instance.vm.trainer.getPrevSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
    return uuid ? true : false;
  }

  render() {
    const { onActive, active, feedbackDrawColor } = this.props;
    const { text } = this.state;
    return (
      <div className={styles.host}>
        <div
          className={
            active
              ? cx(styles.mainSticky, styles.hiddenSticky)
              : styles.mainSticky
          }
        >
          <Button
            color={'blue'}
            icon="chat"
            onClick={() => {
              onActive(true);
            }}
          ></Button>
        </div>
        <div className={active ? styles.main : cx(styles.main, styles.hidden)}>
          <Message>
            <div className={styles.close}>
              <Button
                icon="close"
                size={'mini'}
                onClick={() => {
                  onActive(false);
                }}
              ></Button>
            </div>
            <div>
              <Icon color={'blue'} size={'big'} name={'chat'}></Icon>
              Feedback
            </div>{' '}
            <div className={styles.form}>
              {' '}
              <Form>
                <TextArea
                  value={text}
                  placeholder=""
                  onChange={(e, { value }) => this.setState({ text: value })}
                />
              </Form>
            </div>
            <div className={styles.sendbutton}>
              <Button
                size={'mini'}
                disabled={text === ''}
                onClick={() => {
                  this.onSend();
                  this.setState({ thanks: true });
                  setTimeout(() => {
                    this.setState({ thanks: false });
                    onActive(false);
                  }, 500);
                }}
              >
                {'Senden'}
              </Button>
            </div>
            {Settings._feedbackWithScreenShot && (
              <div className={styles.paint}>
                <Button.Group size={'mini'}>
                  <Button
                    active={feedbackDrawColor === 'black'}
                    icon
                    size="mini"
                    onClick={() => {
                      store.dispatch(actionDrawContainerSetColor('black'));
                    }}
                  >
                    <Icon color="black" name="pencil alternate" />{' '}
                  </Button>
                  <Button
                    active={feedbackDrawColor === 'red'}
                    icon
                    size="mini"
                    onClick={() => {
                      store.dispatch(actionDrawContainerSetColor('red'));
                    }}
                  >
                    <Icon color="red" name="pencil alternate" />{' '}
                  </Button>
                  <Button
                    active={feedbackDrawColor === 'green'}
                    icon
                    size="mini"
                    onClick={() => {
                      store.dispatch(actionDrawContainerSetColor('green'));
                    }}
                  >
                    <Icon color="green" name="pencil alternate" />{' '}
                  </Button>
                  <Button
                    size="mini"
                    icon="eraser"
                    onClick={() => {
                      store.dispatch(actionDrawContainerRemovePoints());
                    }}
                  ></Button>
                </Button.Group>
              </div>
            )}
          </Message>
        </div>
      </div>
    );
  }
}

FeedbackModal = connect(
  (state) => ({
    login: state.login.login,
    language: state.module.language,
    session: state.session.session,
    member: state.member.member,
    user: state.user.user,
    feedbackDrawColor: state.feedback.feedbackDrawColor,
    module: state.module.module,
  }),
  {}
)(FeedbackModal);

export default FeedbackModal;
