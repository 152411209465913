import React, { Component } from 'react';

import { Icon, Button } from 'semantic-ui-react';

import styles from './PauseModal.module.scss';

class PauseModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClick } = this.props;

    return (
      <div>
        <div className={styles.main}></div>
        <div className={styles.message}>
          <Button
            onClick={() => {
              onClick();
            }}
            circular
            size={'huge'}
            icon
          >
            <Icon size={'big'} name="play" />
          </Button>
        </div>
      </div>
    );
  }
}

export default PauseModal;
