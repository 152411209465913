import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Assignments.module.scss';
import { Dropdown, Button, Segment, Flag, Message } from 'semantic-ui-react';

import { connect } from 'react-redux';

import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import { actionSetCompany } from '../../../redux/actions/actionCompany';

class Assignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: null,
    };
  }

  updateView() {
    RestAPI.members();
  }

  render() {
    const {
      companies,
      company,
      modules,
      members,
      moduleAssignments,
    } = this.props;

    const validCompanies = companies || [];
    const optCompanies = arrayDropDown(validCompanies, 'all');

    const rows =
      moduleAssignments &&
      members &&
      moduleAssignments.map((assignment, index) => {
        const member =
          members &&
          members.find((member) => member.uuid === assignment.member_uuid);
        const module =
          modules &&
          modules.find((module) => module.uuid === assignment.module_uuid);

        return {
          assignment,
          member: member && member.name,
          module: module && module.name,
          created: assignment.created,
          tag: assignment.member_tag,
          from: assignment.from,
          until: assignment.until,
        };
      });

    const columns = [
      {
        name: 'Member',
        key: 'member',
        required: true,
        singleLine: true,
      },
      {
        name: 'Module',
        key: 'module',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Tag',
        key: 'tag',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'From',
        key: 'from',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'Until',
        key: 'until',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Assignments:</h3>
        <Message>
          Company:
          {validCompanies && validCompanies.length > 0 && (
            <Dropdown
              placeholder="Filter Company"
              search
              value={company ? company.uuid : 'undefined'}
              selection
              options={optCompanies}
              onChange={(e, d) => {
                const company = companies.find(
                  (company) => company.uuid === d.value
                );
                RestAPI.changeCompany(company && company.uuid).then(() => {
                  RestAPI.moduleAssignmentsByCompany(company.uuid);
                  RestAPI.members();
                  RestAPI.modules();
                });
                store.dispatch(actionSetCompany(company));
              }}
            />
          )}
        </Message>
        <div>
          {rows ? (
            <DataTable
              tableProps={{ selectable: false, compact: true, celled: true }}
              name="assignemnts"
              placeholder="Filter name"
              sortable={true}
              columns={columns}
              rows={rows}
              updateView={() => this.updateView()}
            />
          ) : (
            <div>Select Company</div>
          )}
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Assignments = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    companies: state.company.companies,
    company: state.company.company,
    moduleAssignments: state.module.moduleAssignments,
    modules: state.module.modules,
  }),
  {} // bind account loading action creator
)(Assignments);

export default withRouter(Assignments);
