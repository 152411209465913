import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  actionSetDebug,
  actionSetControl,
} from '../../../redux/actions/actionSettings';
import store from '../../../redux/store';

class KeyHandler extends Component {
  constructor(props) {
    super(props);

    this._keymap = {};
  }

  onKeyUp(event) {
    this._keymap[event.code] = event.type === 'keydown';
  }

  onKeyDown(event) {
    if (!this.props.module) return;

    this._keymap[event.code] = event.type === 'keydown';
    if (
      this._keymap['AltLeft'] &&
      this._keymap['ControlLeft'] &&
      this._keymap['KeyD']
    ) {
      console.log('# debug ' ,!this.props.debug);
      store.dispatch(actionSetDebug(!this.props.debug));
    }
    if (
      this._keymap['AltLeft'] &&
      this._keymap['ControlLeft'] &&
      this._keymap['KeyQ']
    ) {
      console.log('# control ' ,!this.props.control);
      store.dispatch(actionSetControl(!this.props.control));
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', (event) => this.onKeyUp(event));
    window.addEventListener('keydown', (event) => this.onKeyDown(event));
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', (event) => this.onKeyUp);
    window.removeEventListener('keydown', (event) => this.onKeyDown);
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

KeyHandler = connect(
  (state) => ({
    module: state.module.module,
    debug: state.settings.debug,
    control: state.settings.control,
  }),
  {}
)(KeyHandler);

export default KeyHandler;
